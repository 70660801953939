import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid,Typography, AppBar, Toolbar, Badge, Hidden, IconButton, Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  logotext: {
    color: '#fff',
    fontWeight: 600,
    fontSize: '15px',
    letterSpacing: '-0.06px',
    lineHeight: '28px',
		marginTop: '14px',
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  button: {
    fontSize: '13px',
    color: '#fff',
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  const [notifications] = useState([]);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar style={{maxHeight:40,minHeight:40}}>
        <Grid
          container
          spacing={0}
        >
          <Grid
            item
            xs={12}
            style={{padding:'30px 5px 5px 5px', display: 'flex', justifyContent: 'center',}}
          >
            <RouterLink to="/">
              <img src="/assets/tf-logo2.png" style={{display:'flex',color:'#fff'}} width="120" alt="trollforge"/>
            </RouterLink>
          </Grid>
        </Grid>
    {/*
        <Button
          target='_blanc'
          href='/tos'
          className={classes.button}
        >
          Terms of service
        </Button>
        <Button
          target='_blanc'
          href='/pp'
          className={classes.button}
        >
					Privacy Policy
        </Button>
        <Button
          target='_blanc'
          href='/cp'
          className={classes.button}
        >
					Cookie Policy
        </Button>
*/}
        <Button
          target='_blanc'
          href='/imprint'
          className={classes.button}
        >
          Imprint
        </Button>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
