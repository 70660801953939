import React,{useState, useEffect} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssetPack from './AssetPack.js';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const AddAssetDialog = (props) => {

  const classes = useStyles();

  const editor = props.editor;
  const afterClose = props.afterClose;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    if(typeof afterClose !== 'undefined'){
      afterClose();
    }
  };

  return <React.Fragment>
    <Button
      style={{
        width:'100%',
        marginBottom:'5px',
      }}
      variant="outlined"
      color="primary"
      onClick={handleClickOpen}
    >
        Add Asset
      </Button>
      <Dialog
    fullWidth={true}
    maxWidth={'md'}
    open={open}
    onClose={handleClose}
    aria-labelledby="max-width-dialog-title"
  >
    <DialogTitle id="max-width-dialog-title">Select location</DialogTitle>
    <DialogContent>
        <AssetPack
          editor={editor}
          afterAdd={() => { handleClose(); }}
        />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
</React.Fragment>;
}

export default AddAssetDialog;
