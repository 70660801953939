import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { List } from "react-virtualized";
import {
  //  Card,
  //  CardHeader,
  //  CardContent,
  //  CardActions,
  //  Divider,
    Grid,
    Button,
  //  Button,
  TextField,
  MenuItem,
} from '@material-ui/core';

import fonts from './fonts.js';

const useStyles = makeStyles(() => ({
  root: {}
}));


const FontPicker = props => {
  const { className, onChange, style, randomFontTrigger, name, ...rest } = props;

  const [fontpicker_open, setFontpickerOpen] = useState(false);
  const [state, setState] = useState({
    font: 'changa_one',
    font_index: 107,
  });

  const focusFirstItem = () => {
    const els = document.getElementsByClassName("menu-item");
    if (els && els[0]) {
      els[0].focus();
    }
  }

  const selectFont = (value, index) => {
    setState({ ...state, font: value, font_index: index});
    onChange({target:{name:name, value: value}});

    setFontpickerOpen(false);
  }

  const selectRandomFont = () => {

		let font_cnt = fonts.length;
		let random_font_index = false;
		while(!random_font_index || random_font_index > font_cnt){
			random_font_index = Math.floor(Math.random() * 1000);
		}
		random_font_index -= 1;
    selectFont(fonts[random_font_index], random_font_index);

  }

  useEffect(() => {

    //selectRandomFont();

  },[]);

  useEffect(() => {
console.log('pick random font');

    if(randomFontTrigger > 1){
      selectRandomFont();
    }

  },[randomFontTrigger]);

  const renderFontPreviewMenuItem = ({
    key,
    index,
    isScrolling,
    isVisible,
    style
  }) => {
    return (
      <MenuItem
        key={fonts[index]}
        value={fonts[index]}
        style={{
          ...style,
          overflow:        'hidden',
          width:           '300px',
          backgroundColor: (index === state.font_index?'#aaa':'rgba(0,0,0,0)'),
          paddingTop:      '2px',
          paddingBottom:   '2px',
        }}

        onClick={() => selectFont(fonts[index], index)}
      >
        <img
          src={'/assets/font_previews/' + fonts[index] + '.png'}
          style={{
            height: '25px',
          }}
        />
      </MenuItem>
    )
  }

  return (
    <div>
    <TextField
      select
      {...rest}
      value={state.font}
      style={{
        width: '300px',
        height: '35px',
      }}
      SelectProps={{
        MenuProps: {
          onEntered: focusFirstItem
        },
        renderValue: selected => {
          return (<img
            src={'/assets/font_previews/' + selected + '.png'}
            style={{
              height: '25px',
            }}
          />)
        },
        open: fontpicker_open,
        onOpen: () => {
          setFontpickerOpen(true);
        },
        onClose: () => {
          setFontpickerOpen(false);
        }
      }}
    >
      <List
        width={300}
        height={400}
        rowCount={fonts.length}
        rowHeight={35}
        rowRenderer={renderFontPreviewMenuItem}
        scrollToIndex={state.font_index}
      />
    </TextField>
           {/*<Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={6}

            >
              <Button onClick={() => {selectFont(fonts[(state.font_index+1)], state.font_index+1);localStorage.fontIndex = (parseInt(state.font_index)+1);let workingFonts = JSON.parse(localStorage.workingFonts); workingFonts.push(fonts[state.font_index]); localStorage.workingFonts = JSON.stringify(workingFonts)}}
               style={{width: '100%'}}
               variant='contained'
              >
                FONT WORKS
							</Button>
            </Grid>
            <Grid
              item
              xs={6}

            >
              <Button onClick={() => {selectFont(fonts[(state.font_index+1)], state.font_index+1);localStorage.fontIndex = (parseInt(state.font_index)+1);}}
               style={{width: '100%'}}
               variant='contained'
              >
                FONT DAMAGED
							</Button>
            </Grid>
          </Grid>*/}
          </div>
  );
};

export default FontPicker;
