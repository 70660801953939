import React, {useState, useEffect} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Provider } from './lib/js/State.tsx';

import { makeStyles } from '@material-ui/styles';
import { 
  Grid,
  Button,
  Slider,
  Typography,
  AppBar,
  Tabs,
  TabPanel,
  Tab,
} from '@material-ui/core';
import Trollshop from './lib/js/editor.js';
import AssetPack from './lib/js/AssetPack.js';
import ObjectBrowser from './lib/js/ObjectBrowser.js';

const useStyles = makeStyles(theme => ({
  root: {
    padding:    theme.spacing(4),
    width:      '100%',
    display:    'flex',
    alignItems: 'stretch',
  },
	sidebar: {
		order: 2,
		[theme.breakpoints.up("md")]: {
			order: 1
		}
	},
	output: {
		order: 1,
		[theme.breakpoints.up("md")]: {
			order: 2
		}
	}
}));

var trollshop = null;

var mouseDown = 0;
document.body.onmousedown = function() { 
    mouseDown = 1;
}
document.body.onmouseup = function() {
    mouseDown = 0;
}

var objectBrowserUpdateTriggerCounter = 0;



const ModelEditor = () => {

	const is_mobile = useMediaQuery('(max-width:959)');

  const classes = useStyles();

  const [currentAssetLibrary, setCurrentAssetLibrary] = useState(null);
  const [models, setModels] = useState([]);
  const [boneSlots, setBoneSlots] = useState([]);
  const [basemeshs, setBasemeshs] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selected, setSelected] = React.useState([]);
  const [activePoseSlider, setActivePoseSlider] = useState(null);
  const [poseStates, setPoseStates] = useState({});
  const [transform_controls_active, setTransformControlsActive] = useState(false);

  const [objectBrowserUpdateTrigger, setObjectBrowserUpdateTrigger] = useState(0);


  const updatePoseState = (event, newValue) => {

    if(activePoseSlider){
      var poseStates_tmp = {...poseStates};
      poseStates_tmp[activePoseSlider.name] = newValue;
      selectedModel.actions[activePoseSlider.name].weight = newValue;
      setPoseStates(poseStates_tmp);
    }

  };

  const [activeTab, setActiveTab] = React.useState(0);

  const showTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const triggerObjectBrowserUpdate = () => {

    objectBrowserUpdateTriggerCounter += 1;
    setObjectBrowserUpdateTrigger(objectBrowserUpdateTriggerCounter+1);

  }

  useEffect(() => {
    trollshop = new Trollshop.Editor();
    document.trollshop = trollshop;

    var base_model = new Trollshop.Model({
      editor:   trollshop,
      //basemesh: '/testfiles/base_armature_test_1.1.gltf',
      //basemesh: '/3d_assets/basemeshes/id_basemesh_1.3.gltf',
      //basemesh: '/3d_assets/basemeshes/id_basemesh_2.01.gltf.glb',
      basemesh: '/model_editor/3d_assets/basemeshes/id_basemesh_2.04.gltf.glb',
      //basemesh: '/model_editor/3d_assets/basemeshes/id_basemesh_2.05.gltf.glb.gltf',
      //basemesh: '/model_editor/3d_assets/basemeshes/mixamo_test.gltf',
      //basemesh: '/3d_assets/basemeshes/id_basemesh_2.01.gltf',
      after_init: () => {

        var asset_library = new Trollshop.AssetLibrary({
          editor:   trollshop,
          path:     '/model_editor/3d_assets/asset_libraries/infernal_dwarf/index.json',
          after_init: () => {

            trollshop.add_asset_library(asset_library);
            //setCurrentAssetLibrary(trollshop.current_asset_library);
            //console.log(trollshop.current_asset_library.assets);

            trollshop.loadModel(4);
          },
        });
				console.log('basemodel loaded');
				console.log(base_model);
				
        //base_model.loadMixamoTestAnimation.call(base_model);

        setSelectedModel(base_model);
        setModels([base_model]);
      },
      onAddAsset: (model) => {
        triggerObjectBrowserUpdate();
      }
    });

    trollshop.add_model(base_model);

  },[]);

  const download_stl = () => {
    console.log('DOWNLOAD STL');
    if(document.trollshop){

      document.trollshop.export_stl();
    }
  };

  const renderPoses = () => {

    return <Grid
      container
      spacing={4}
    >
      { selectedModel && Object.keys(selectedModel.actions).map((key, index) => {
        if(typeof poseStates[key] !== 'undefined'){
          return <Grid
            item
            key={index}
            lg={4}
            md={4}
            xl={4}
            xs={12}
          >
            <Typography id="continuous-slider" gutterBottom>
              {key}
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
              </Grid>
              <Grid item xs>
                <Slider
                  min={0}
                  step={0.01}
                  max={1}
                  value={poseStates[key]}
                  onChange={updatePoseState}
                  aria-labelledby="continuous-slider"
                  name={key}
                  onMouseUp={() => { setActivePoseSlider(null); }}
                  onMouseOver={(event) => {
                    if(!mouseDown){
                      var input_el = event.target.querySelector('input');
                      if(!input_el){

                        input_el = event.target.parentElement.querySelector('input');
                      }
                      setActivePoseSlider(input_el);
                    }
                  }}
                  onMouseDown={(event) => {
                    var input_el = event.target.querySelector('input');
                    if(!input_el){

                      input_el = event.target.parentElement.querySelector('input');
                    }
                    setActivePoseSlider(input_el);
                  }}
                />
              </Grid>
              <Grid item>
              </Grid>
            </Grid>
          </Grid>;
        }
      }) }
    </Grid>;
  };

  useEffect(() => {
    if(selectedModel){
      //console.log('selectedModel');
      //console.log(selectedModel.actions );
      var poseStates_tmp = {};
      for(var key in selectedModel.actions){
        poseStates_tmp[key] = selectedModel.actions[key].weight;
      }
      setPoseStates(poseStates_tmp);
    }
  },[selectedModel]);

  const findBasemeshs = (objects) => {
    var basemeshs_tmp = [];
    for(var i = 0;i < objects.length;i++){
      if(typeof objects[i].userData.is_basemesh !== 'undefined' && objects[i].userData.is_basemesh){
        basemeshs_tmp.push(objects[i]);
      }
      basemeshs_tmp = [...basemeshs_tmp, ...findBasemeshs(objects[i].children)];
    }
    return basemeshs_tmp;
  }

  useEffect(() => {
    //find basemeshes
    var basemeshs_tmp = [];
    for(var i = 0;i < models.length;i++){
      basemeshs_tmp = [...basemeshs_tmp, ...findBasemeshs(models[i].scene.children)];
    }
    console.log(basemeshs_tmp);
    console.log('found basemeshs');
    console.log(basemeshs_tmp);
    setBasemeshs(basemeshs_tmp);
  },[models]);

  return (
    <Provider>
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
          style={{
            alignItems: 'stretch',
          }}
        >
          <Grid
            item
            lg={4}
            md={4}
            xl={4}
            xs={12}
            style={{
              maxHeight: '100%',
              overflow: 'auto',
							overflowY: 'scroll',
							overflowX: 'hidden',
            }}
						className={classes.sidebar}
          >
            {false &&
            <Button onClick={download_stl} style={{marginBottom:'10px',}} variant="contained" color="secondary">
              Download STL
            </Button>
            }
            <ObjectBrowser
              currentAssetLibrary={currentAssetLibrary}
              editor={trollshop}
              models={models}
              selectedModel={selectedModel}
              basemeshs={basemeshs}
              selected={selected}
              setSelected={setSelected}
              objectBrowserUpdateTrigger={objectBrowserUpdateTrigger}
              triggerObjectBrowserUpdate={triggerObjectBrowserUpdate}
            />
          </Grid>
          <Grid
            item
            id="trollshop_container"
            lg={8}
            md={8}
            xl={8}
            xs={12}
            style={{
              padding: 0,
              height: '100%',
            }}
						className={classes.output}
          >
            <canvas id="trollshop"></canvas>
          </Grid>
        </Grid>
      </div>
    </Provider>
  );
};

export default ModelEditor;
