import React,{useState, useEffect} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { 
  Grid,
  Button,
  Slider,
  Typography,
  AppBar,
  Tabs,
  TabPanel,
  Tab,
} from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const AssetPack = (props) => {

  const classes = useStyles();

  const currentAssetLibrary = props.currentAssetLibrary;
  const editor = props.editor;

  const afterAdd = props.afterAdd;

  const renderAssetSet = (asset_set, key, index) => {
    return <ExpansionPanel
        key={'panel' + index + '-header'}
      >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={'panel' + index + '-content'}
        id={'panel' + index + '-header'}
      >
        <Typography className={classes.heading}>
          {key}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            lg={4}
            md={4}
            xl={4}
            xs={12}
            key={"child_none"}
          >
            <Button onClick={() => {
              //console.log(asset_set[Object.keys(asset_set)[0]].typeset.target_bone);
              //console.log(key);
              editor.clear_bone(asset_set[Object.keys(asset_set)[0]].typeset.target_bone, key)

              if(typeof afterAdd !== 'undefined'){
                afterAdd();
              }

            }}>
              none
            </Button>

          </Grid>
          { asset_set && Object.keys(asset_set).map((key, index) => {
            let asset = asset_set[key];
            //console.log(asset);
            return <Grid
              item
              lg={4}
              md={4}
              xl={4}
              xs={12}
              key={"child_" + index}
            >
              <Button onClick={() => {

                asset.load();

                if(typeof afterAdd !== 'undefined'){
                  afterAdd();
                }

              }}>
                <img src={asset.thumbnail} alt="" style={{maxWidth:'100%'}}/>
              </Button>

            </Grid>;
          })}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>;
  }

  return <Grid
    container
    spacing={4}
    style={{
      padding:'10px',
    }}
  >
    <Grid
      item
      xs={12}
    >
      { editor && editor.asset_libraries[0] && Object.keys(editor.asset_libraries[0].assets).map((key, index) => {
        //console.log('asset ' + key);
        let asset_set = editor.asset_libraries[0].assets[key];
        return renderAssetSet(asset_set, key, index)

      })}
    </Grid>
  </Grid>;
}

export default AssetPack;
