import React,{useState, useEffect} from 'react';
import * as THREE from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
//
import { STLExporter } from './STLExporter';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import * as dat from 'three/examples/jsm/libs/dat.gui.module.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';

//import { PMREMGenerator } from 'three/examples/jsm/pmrem/PMREMGenerator.js';
//import { PMREMCubeUVPacker } from 'three/examples/jsm/pmrem/PMREMCubeUVPacker.js';

const $ = require('jquery');

const show_dat_gui = false;
//require('./STLExporter_new.js');
//require './preset.js';
dat.GUI.prototype.removeFolder = function(name) {
  var folder = this.__folders[name];
  if (!folder) {
    return;
  }
  folder.close();
  this.__ul.removeChild(folder.domElement.parentNode);
  delete this.__folders[name];
  this.onResize();
};

var humanoid_bone_slots = [
  'hat',
  'head',
  'beard',
  'torso',
  'shoulder',
  'shoulder_R',
  'hand_weapon',
  'shield',
  'pants',
  'knee',
  'knee_R',
  'boot',
  'boot_R',
];
var translate_bone_slot = {
  beard: 'Beard',
  boot: 'Left foot',
  boot_R: 'Right foot',
  hand_weapon: 'Right hand',
  hat: 'Hat',
  head: 'Head',
  knee: 'Left knee',
  knee_R: 'Right knee',
  pants: 'Pants',
  shield: 'Left hand',
  shoulder: 'Left Shoulder',
  shoulder_R: 'Right shoulder',
  torso: 'Torso',
};


//animation bone limiation map
var animation_bones = {
  'left_arm':[
    'upper_armL',
    'forearmL',
    'handL',
    'shoulderL',
  ],
  'left_hand':[
    'palm01L',
    'palm02L',
    'palm03L',
    'palm04L',
    'f_index01L',
    'f_index02L',
    'f_index03L',
    'thumb01L',
    'thumb02L',
    'thumb03L',
    'f_middle01L',
    'f_middle02L',
    'f_middle03L',
    'f_ring01L',
    'f_ring02L',
    'f_ring03L',
    'f_pinky01L',
    'f_pinky02L',
    'f_pinky03L',
  ],
  'legs':[
    'pelvisL',
    'pelvisR',
    'thighL',
    'thighR',
    'shinL',
    'shinR',
    'footL',
    'footR',
    'toeL',
    'toeR',
    'heel01L',
    'heel01R',
    'heel02L',
    'heel02R',
  ],
  'right_arm':[
    'upper_armR',
    'forearmR',
    'handR',
    'shoulderR',
  ],
  'right_hand':[
    'palm01R',
    'palm02R',
    'palm03R',
    'palm04R',
    'f_index01R',
    'f_index02R',
    'f_index03R',
    'thumb01R',
    'thumb02R',
    'thumb03R',
    'f_middle01R',
    'f_middle02R',
    'f_middle03R',
    'f_ring01R',
    'f_ring02R',
    'f_ring03R',
    'f_pinky01R',
    'f_pinky02R',
    'f_pinky03R',
  ],
  'torso':[
    'spine001',
    'spine002',
    'spine003',
    'spine004',
    'spine005',
    'spine006',
  ],
};


const mixamo_makehuman_bone_map = {
//"mixamorigHips":             "",
//"mixamorigSpine":            "spine001",
//"mixamorigSpine1":           "spine002",
//"mixamorigSpine2":           "spine003",
//"mixamorigNeck":             "spine004",
//"mixamorigHead":             "spine006",
//'mixamorigroot': 'Root',
	//'mixamorigHips':             'Pelvis',
	'mixamorigSpine':            'spine001',
	'mixamorigSpine1':           'spine002',
	'mixamorigSpine2':           'spine003',
	'mixamorigLeftShoulder':     'shoulderL',
	'mixamorigLeftArm':          'upper_armL',
	'mixamorigLeftForeArm':      'forearmL',
	'mixamorigLeftHand':         'handL',
	'mixamorigRightShoulder':    'shoulderL',
	'mixamorigRightArm':         'upper_armR',
	'mixamorigRightForeArm':     'forearmR',
	'mixamorigRightHand':        'handR',
	//'Neck1':            'neck_01',
	//'Neck':             'neck_01',
	//'Head':             'head',
	'mixamorigLeftUpLeg':        'thighL',
	'mixamorigLeftLeg':          'shinL',
	'mixamorigLeftFoot':         'footL',
	'mixamorigRightUpLeg':       'thighR',
	'mixamorigRightLeg':         'shinL',
	'mixamorigRightFoot':        'footR',
	//'LeftHandIndex1':   'index_01_l',
	//'LeftHandIndex2':   'index_02_l',
	//'LeftHandIndex3':   'index_03_l',
	//'LeftHandMiddle1':  'middle_01_l',
	//'LeftHandMiddle2':  'middle_02_l',
	//'LeftHandMiddle3':  'middle_03_l',
	//'LeftHandPinky1':   'pinky_01_l',
	//'LeftHandPinky2':   'pinky_02_l',
	//'LeftHandPinky3':   'pinky_03_l',
	//'LeftHandRing1':    'ring_01_l',
	//'LeftHandRing2':    'ring_02_l',
	//'LeftHandRing3':    'ring_03_l',
	//'LeftHandThumb1':   'thumb_01_l',
	//'LeftHandThumb2':   'thumb_02_l',
	//'LeftHandThumb3':   'thumb_03_l',
	//'RightHandIndex1':  'index_01_r',
	//'RightHandIndex2':  'index_02_r',
	//'RightHandIndex3':  'index_03_r',
	//'RightHandMiddle1': 'middle_01_r',
	//'RightHandMiddle2': 'middle_02_r',
	//'RightHandMiddle3': 'middle_03_r',
	//'RightHandPinky1':  'pinky_01_r',
	//'RightHandPinky2':  'pinky_02_r',
	//'RightHandPinky3':  'pinky_03_r',
	//'RightHandRing1':   'ring_01_r',
	//'RightHandRing2':   'ring_02_r',
	//'RightHandRing3':   'ring_03_r',
	//'RightHandThumb1':  'thumb_01_r',
	//'RightHandThumb2':  'thumb_02_r',
	//'RightHandThumb3':  'thumb_03_r',
	//'LeftToeBase':      'ball_l',
	//'RightToeBase':     'ball_r'

//"mixamorigLeftShoulder":     "",
//"mixamorigLeftArm":          "",
//"mixamorigLeftForeArm":      "",
//"mixamorigLeftHand":         "",
//"mixamorigLeftHandThumb1":   "",
//"mixamorigLeftHandThumb2":   "",
//"mixamorigLeftHandThumb3":   "",
//"mixamorigLeftHandIndex1":   "",
//"mixamorigLeftHandIndex2":   "",
//"mixamorigLeftHandIndex3":   "",
//"mixamorigLeftHandMiddle1":  "",
//"mixamorigLeftHandMiddle2":  "",
//"mixamorigLeftHandMiddle3":  "",
//"mixamorigLeftHandRing1":    "",
//"mixamorigLeftHandRing2":    "",
//"mixamorigLeftHandRing3":    "",
//"mixamorigLeftHandPinky1":   "",
//"mixamorigLeftHandPinky2":   "",
//"mixamorigLeftHandPinky3":   "",
//"mixamorigRightShoulder":    "",
//"mixamorigRightArm":         "",
//"mixamorigRightForeArm":     "",
//"mixamorigRightHand":        "",
//"mixamorigRightHandPinky1":  "",
//"mixamorigRightHandPinky2":  "",
//"mixamorigRightHandPinky3":  "",
//"mixamorigRightHandRing1":   "",
//"mixamorigRightHandRing2":   "",
//"mixamorigRightHandRing3":   "",
//"mixamorigRightHandMiddle1": "",
//"mixamorigRightHandMiddle2": "",
//"mixamorigRightHandMiddle3": "",
//"mixamorigRightHandIndex1":  "",
//"mixamorigRightHandIndex2":  "",
//"mixamorigRightHandIndex3":  "",
//"mixamorigRightHandThumb1":  "",
//"mixamorigRightHandThumb2":  "",
//"mixamorigRightHandThumb3":  "",
//"mixamorigLeftUpLeg":        "",
//"mixamorigLeftLeg":          "",
//"mixamorigLeftFoot":         "",
//"mixamorigLeftToeBase":      "",
//"mixamorigRightUpLeg":       "",
//"mixamorigRightLeg":         "",
//"mixamorigRightFoot":        "",
//"mixamorigRightToeBase":     ""
};

var Trollshop = {
  DEBUG: true,
};

Trollshop.Model = class {

  constructor( options ){
    this.initial_options = null;
    this.editor          = null;
    this.scene           = null;
    this.poses_folder    = null;
    this.debug_folder    = null;
    this.three_object    = null;

    // keys are bone names, values bones
    this.bones           = {};
    // keys are bone names, values arrays of 3d assets attached to that bone
    this.bone_slots      = {};

    this.mixer           = null;
    this.actions         = {};
    this.actions_player  = {};


    if(typeof options !== 'undefined'){
      this.initial_options = options;
    }

    if(typeof options.editor !== 'undefined'){
      this.editor = options.editor;
      this.scene  = this.editor.scene;
    }

    if(typeof options.onAddAsset !== 'undefined'){
      this.onAddAsset = options.onAddAsset;
    }

    if(typeof options.before_init !== 'undefined'){
      this.before_init = options.init;
    } else {
      this.before_init = () => {};
    }

    if(typeof options.after_init !== 'undefined'){
      this.after_init = options.after_init;
    } else {
      this.after_init = () => {};
    }

    this.init();
  }

  init(){
    this.before_init();

    if(typeof this.initial_options.basemesh !== 'undefined'){
      this.load_base_mesh(() => {

        this.after_init();

      });
    }

  }

  set_scene( scene ){
    this.scene = scene;
  }

  loop(){
    this.loop_func(this);
  }

	loadMixamoTestAnimation(){
var self = this;

    var fbxloader = new FBXLoader();
    //fbxloader.load( '/model_editor/3d_assets/animations/test1.fbx', function ( object ) {

    //  //let mixer = new THREE.AnimationMixer( object );

    //  for ( var i = 0; i < object.animations.length; i++ ) {
		//		console.log('Mixamo animation');
		//		console.log(object.animations[i]);
		//		for ( var k = 0; k < object.animations[i].tracks.length; k++ ) {
		//			let tmp_name_array = object.animations[i].tracks[k].name.split('.');
		//			let tmp_name = tmp_name_array[0];
		//			if(typeof mixamo_makehuman_bone_map[tmp_name] !== 'undefined'){
		//				object.animations[i].tracks[k].name = mixamo_makehuman_bone_map[tmp_name] + '.' + tmp_name_array[1];
		//			}
		//		}

		//		object.animations[i].name = object.animations[i].name + '1';
		//		let tmp_clip_name = object.animations[i].name;
    //    self.actions[ tmp_clip_name ] = self.mixer.clipAction( object.animations[i], self.three_object );
    //    self.actions[ tmp_clip_name ].loop = THREE.LoopOnce;
    //    self.actions[ tmp_clip_name ].clampWhenFinished = true;
    //    self.actions[ tmp_clip_name ].weight = 0;
    //    self.actions[ tmp_clip_name ].enabled = true;
    //    self.actions[ tmp_clip_name ].setEffectiveTimeScale( 1 );
    //    self.actions[ tmp_clip_name ].setEffectiveWeight( 0 );
    //    self.actions[ tmp_clip_name ].play();

		//	}
		//	
		//	if(typeof self.onUpdateActions !== 'undefined'){
		//		self.onUpdateActions();
		//	}

    //} );

 //   fbxloader.load( '/model_editor/3d_assets/animations/test2.fbx', function ( object ) {

 //     //let mixer = new THREE.AnimationMixer( object );

 // 			console.log(object);
 //     for ( var i = 0; i < object.animations.length; i++ ) {
 // 			console.log('Mixamo animation');
 // 			console.log(object.animations[i]);

 // 			let tmp_tracks = [];

 // 			for ( var k = 0; k < object.animations[i].tracks.length; k++ ) {
 // 				let tmp_name_array = object.animations[i].tracks[k].name.split('.');
 // 				let tmp_name = tmp_name_array[0];
 // 				if(typeof mixamo_makehuman_bone_map[tmp_name] !== 'undefined' && tmp_name_array[1] !== 'position'){
 // 					object.animations[i].tracks[k].name = mixamo_makehuman_bone_map[tmp_name] + '.' + tmp_name_array[1];
 // 					tmp_tracks.push(object.animations[i].tracks[k]);
 // 				}
 // 			}
 // 			object.animations[i].tracks = tmp_tracks;
 // 			object.animations[i].name = object.animations[i].name + '2';
 // 			let tmp_clip_name = object.animations[i].name;
 //       self.actions[ tmp_clip_name ] = self.mixer.clipAction( object.animations[i], self.three_object );
 //       //self.actions[ tmp_clip_name ].loop = THREE.LoopOnce;
 //       //self.actions[ tmp_clip_name ].clampWhenFinished = true;
 //       self.actions[ tmp_clip_name ].weight = 1;
 //       self.actions[ tmp_clip_name ].enabled = true;
 //       //self.actions[ tmp_clip_name ].setEffectiveTimeScale( 1 );
 //       //self.actions[ tmp_clip_name ].setEffectiveWeight( 0 );
 //       self.actions[ tmp_clip_name ].play();

 // 		}
 // 		
 // 		if(typeof self.onUpdateActions !== 'undefined'){
 // 			self.onUpdateActions();
 // 		}

 // 		//self.editor.scene.add(object);
 //   } );

    //var loader = new GLTFLoader();
    //loader.load( '/model_editor/3d_assets/animations/test1.gltf', function ( object ) {
    //  for ( var i = 0; i < object.animations.length; i++ ) {
		//		console.log('Mixamo animation');
		//		console.log(object.animations[i]);

		//		let tmp_tracks = [];

		//		//for ( var k = 0; k < object.animations[i].tracks.length; k++ ) {
		//		//	let tmp_name_array = object.animations[i].tracks[k].name.split('.');
		//		//	let tmp_name = tmp_name_array[0];
		//		//	if(typeof mixamo_makehuman_bone_map[tmp_name] !== 'undefined'){
		//		//	//if(typeof mixamo_makehuman_bone_map[tmp_name] !== 'undefined' && tmp_name_array[1] !== 'position'){
		//		//		object.animations[i].tracks[k].name = mixamo_makehuman_bone_map[tmp_name] + '.' + tmp_name_array[1];
		//		//		tmp_tracks.push(object.animations[i].tracks[k]);
		//		//	}
		//		//}
		//		object.animations[i].tracks = tmp_tracks;
		//		object.animations[i].name = object.animations[i].name + '2';
		//		let tmp_clip_name = object.animations[i].name;
    //    self.actions[ tmp_clip_name ] = self.mixer.clipAction( object.animations[i] ).play();
    //    //self.actions[ tmp_clip_name ] = self.mixer.clipAction( object.animations[i], self.three_object );
    //    //self.actions[ tmp_clip_name ].loop = THREE.LoopOnce;
    //    //self.actions[ tmp_clip_name ].clampWhenFinished = true;
    //    //self.actions[ tmp_clip_name ].weight = 1;
    //    //self.actions[ tmp_clip_name ].enabled = true;
    //    //self.actions[ tmp_clip_name ].setEffectiveTimeScale( 1 );
    //    //self.actions[ tmp_clip_name ].setEffectiveWeight( 0 );
    //    //self.actions[ tmp_clip_name ].play();

		//	}
		//	object.scene.traverse( function ( object ) {
		//		if ( object.isMesh ) object.castShadow = true;
		//	} )
		//	self.editor.scene.add(object.scene);
		//	
		//	if(typeof self.onUpdateActions !== 'undefined'){
		//		self.onUpdateActions();
		//	}
		//});
	}

  load_base_mesh( done ){
    var self = this;
    var loader = new GLTFLoader();

    var gltf_scene = null;
    loader.load( self.initial_options.basemesh, function ( gltf ) {
      gltf_scene = gltf;
      var oben_bone = null;

      gltf.scene.traverse( function ( child ) {
        if ( child.isMesh || child.type === 'SkinnedMesh' ) {

          console.log('MATERIAL: BASEMESH');
          //if(child.name === "base_mesh_11_solid"){
            child.material = self.editor.get_material.call(self.editor);
          //}
          if(typeof child.userData.is_basemesh !== 'undefined' && child.userData.is_basemesh){
            self.three_object = child;
          }
        }
      } );

      if(Trollshop.DEBUG){
        console.log('LOAD BASEMESH');
        console.log(gltf);
      }

      self.scene.add( gltf.scene );

      self.scene.updateMatrixWorld();
      // ANIMATIONS
      //
      self.mixer        = new THREE.AnimationMixer( gltf.scene );

      if(show_dat_gui){
        self.poses_folder = self.editor.gui.addFolder( "POSES" );
      }

      var animations = gltf.animations;
      console.log(gltf.animations);
      for ( var i = 0; i < animations.length; i++ ) {
        var clip = animations[ i ];

        //find matching animation bone map
        var animation_bone_map = [];
        var animation_prefix = 'none';
        for(var key in animation_bones){
          if(clip.name.indexOf(key) !== -1){
            animation_bone_map = animation_bones[key];
            animation_prefix = key;
          }
        }

        //filter tracks using animation_bone_map
        var tmp_tracks = [];
        for(var k = 0;k < clip.tracks.length;k++){
          var tmp_track_name = clip.tracks[k].name.split('.')[0];
          if(animation_bone_map.indexOf(tmp_track_name) !== -1){
            tmp_tracks.push(clip.tracks[k]);
          }
        }
        clip.tracks = tmp_tracks;

        //console.log(animation_bone_map);
        //if(clip.name.indexOf('pose_') !== -1){
        self.actions[ clip.name ] = self.mixer.clipAction( clip );
        self.actions[ clip.name ].loop = THREE.LoopOnce;
        self.actions[ clip.name ].clampWhenFinished = true;
        self.actions[ clip.name ].weight = 0;
        if(show_dat_gui){
          self.poses_folder.add( self.actions[ clip.name ], 'weight', 0.0, 1.0, 0.01 );
          self.poses_folder.__controllers[i].name( clip.name );
        }
        self.actions[ clip.name ].enabled = true;
        self.actions[ clip.name ].setEffectiveTimeScale( 1 );
        self.actions[ clip.name ].setEffectiveWeight( 0 );
        self.actions[ clip.name ].play();
        var clipname = clip.name + '';
        self.actions_player[ clip.name ] = ((clipname, animation_prefix) => {
          return () => {
            //console.log(self.actions[ clipname ]);
            //stop all animations with the same animation prefix
            //console.log('stop all ' + animation_prefix + ' actions');
            for(var key in self.actions){
              if(key.indexOf(animation_prefix) !== -1){

                self.actions[ key ].stop();
                //self.actions[ key ].reset();
              }
            }
            //self.actions[ clipname ].startAt(0);
            self.actions[ clipname ].play();
            //self.actions[ clipname ].halt(1);
          };
        })(clipname, animation_prefix);
        //console.log( clipname );
        //console.log(self.actions[ clipname ]);
        //console.log(self.actions[ clipname ]['_clip'].tracks);
        //self.actions[ clipname ].play();
        //}
      }

      //for(var key in self.actions_player){
      //  self.poses_folder.add(self.actions_player, key);
      //}


      //setTimeout(() => {


      if(show_dat_gui){
        self.debug_folder = self.editor.gui.addFolder( "DEBUG" );
      }

      //var control_names = [];
      //self.scene.traverse( function ( child ) {
      //  if(child.name && control_names.indexOf(child.name) === -1){

      //    control_names.push(child.name);
      //    //self.editor.add_gui_debug_folder( child , self.debug_folder );

      //  }
      //});
      //}, 100);

      // Init bone slots
      for(var i = 0;i < humanoid_bone_slots.length;i++){
        self.bone_slots[humanoid_bone_slots[i]] = {
          name: translate_bone_slot[humanoid_bone_slots[i]],
          items: [],
        };
      }

      done();
    });
  }

  get_asset_config(){

    // collect asset data
    let assets_config = {};
    for(var key in this.bone_slots){
      assets_config[key] = [];
      for(var i = 0;i < this.bone_slots[key].items.length; i++){
        assets_config[key].push({
          type_name:          this.bone_slots[key].items[i].type_name,
          original_type_name: this.bone_slots[key].items[i].original_type_name,
          name:               this.bone_slots[key].items[i].name,
          parent:             this.bone_slots[key].items[i].mesh.parent.name,
          position:           this.bone_slots[key].items[i].mesh.position,
          rotation:           this.bone_slots[key].items[i].mesh.rotation,
          scale:              this.bone_slots[key].items[i].mesh.scale,
        });
      }
    }
    return assets_config;
  }

  get_pose_config(){
    let pose_config = {};

		for(var key in this.actions){
			pose_config[key] = this.actions[key].weight;
		}

    return pose_config;
  }

  get_model_config(){

		let model_config = {
      poseStates: this.get_pose_config(),
      assets: this.get_asset_config(),
		};
		return model_config;
  }

  loadAssets(model_config) {

    console.log('load Assets')
    for(var key in model_config.assets){
      for(var i = 0;i < model_config.assets[key].length; i++){
				let asset_config = model_config.assets[key][i];
        this.editor.asset_libraries[0].assets[model_config.assets[key][i].type_name][model_config.assets[key][i].name].load((asset) => {
console.log('LOAD ASSET');
console.log(asset);
console.log(asset_config);
					asset.apply_transforms(asset_config);
				});
      }
    }
  }

  loadPose(model_config) {
		console.log('LOAD POSE');
    
    var poseStates_tmp = this.get_pose_config();
    for(var key in model_config.poseStates){
      poseStates_tmp[key] = model_config.poseStates[key];
      this.actions[key].weight = model_config.poseStates[key];
    }

    if(typeof this.afterLoadPose !== 'undefined'){
      this.afterLoadPose(this);
    }
  }

  removeAllAssets() {

    for(var key in this.bone_slots){
      if(typeof this.bone_slots[key].items[0] !== 'undefined'){
        this.editor.clear_bone(this.bone_slots[key].items[0].mesh.parent, this.bone_slots[key].items[0].type_name)
      }
    }

    if(typeof this.onAssetsUpdate !== 'undefined'){
      this.onAssetsUpdate(this);
    }
  }

  attach_asset( asset ){

  }

  remove_asset( asset ){

  }
};

Trollshop.Asset = class {

  constructor( options ){
    this.name            = '';
    this.parent_object   = '';
    this.initial_options = null;
    this.mesh            = null;

    if(typeof options !== 'undefined'){
      this.initial_options = options;
    }

    this.editor          = null;

    if(typeof options.editor !== 'undefined'){
      this.editor = options.editor;
    }

    if(typeof options.asset_library !== 'undefined'){
      this.asset_library = options.asset_library;
    }

    if(typeof options.typeset !== 'undefined'){
      this.typeset = options.typeset;
    }

    if(typeof options.type_name !== 'undefined'){
      this.type_name = options.type_name;
    }

    // used to build the correct path for mirrored types
    if(typeof options.original_type_name !== 'undefined'){
      this.original_type_name = options.original_type_name;
    }

    if(typeof options.key !== 'undefined'){
      this.name = options.key;
    }

    if(typeof options.after_attach !== 'undefined'){
      this.after_attach = options.after_attach;
    }

    this.load = this.create_asset_loading_function(this.type_name, this.initial_options.key, this.initial_options.typeset);
  }

  load_mesh( done ){
  }

  attach_to( bone, done ){
    var self = this;

    self.mesh.material = self.asset_library.editor.get_material.call(self.asset_library.editor);

    self.apply_transforms( self.typeset.transform );
    self.mesh.name = 'asset_' + self.type_name + self.name;
		
		if(self.typeset.target_bone){
			self.typeset.target_bone.add( self.mesh );
		}

    if(typeof self.after_attach !== 'undefined'){
      //console.log(self);
      self.after_attach.call(self);
    }

    if(typeof done !== 'undefined'){
      done();
    }
  }

  remove(){

  }

  create_asset_loading_function(typename, key, typeset){
    var self = this;
    var target_bone = typeset.target_bone;
    var transform = typeset.transform;
    self.asset_path = '/model_editor/3d_assets/asset_libraries/' + self.asset_library.name + '/' + self.original_type_name + '/';
    self.thumbnail = self.asset_path + key + '.png';

    console.log('load: ' + self.asset_path + key + '.OBJ');

    return (done) => {
      self.asset_library.loader.load( self.asset_path + key + '.OBJ', function ( obj ) {

        self.asset_library.clear_bone( target_bone , typename , self.editor.current_model);

        obj.traverse( function ( child ) {

          if ( child.isMesh ){
            self.mesh = child;
            self.mesh.asset_obj = self;

            self.attach_to( target_bone );

            if(show_dat_gui){
              var folder = self.asset_library.editor.add_gui_debug_folder( child , self , typename );
            }

            self.editor.current_model.bone_slots[typename].items.push(self);

						if(typeof done !== 'undefined'){
							done(self);
						}

            if(typeof self.editor.current_model.onAddAsset !== 'undefined'){
              self.editor.current_model.onAddAsset(self);
            }
          }

        } );

      });
    };
  }

  apply_transforms( source ){

    this.mesh.position.x = source.position.x;
    this.mesh.position.y = source.position.y;
    this.mesh.position.z = source.position.z;
		if(typeof source.rotation._x !== 'undefined'){
			this.mesh.rotation.x = source.rotation._x;
		}
		if(typeof source.rotation._y !== 'undefined'){
    this.mesh.rotation.y = source.rotation._y;
		}
		if(typeof source.rotation._z !== 'undefined'){
    this.mesh.rotation.z = source.rotation._z;
		}
		if(typeof source.rotation.x !== 'undefined'){
			this.mesh.rotation.x = source.rotation.x;
		}
		if(typeof source.rotation.y !== 'undefined'){
    this.mesh.rotation.y = source.rotation.y;
		}
		if(typeof source.rotation.z !== 'undefined'){
    this.mesh.rotation.z = source.rotation.z;
		}
    this.mesh.scale.x    = source.scale.x;
    this.mesh.scale.y    = source.scale.y;
    this.mesh.scale.z    = source.scale.z;

  }

  transform_controls() {
    console.log('transform controls');
    console.log( this );

    this.asset_library.editor.transform_controls.detach(  );
    this.asset_library.editor.transform_controls.attach( this.mesh );
  }
};

Trollshop.AssetTypeset = class {

  constructor( options ){
    this.name = '';
    this.initial_options = null;

    // asset library. keys are asset type names and value are objects containing asset obj configurations (keys of second object are filenames)
    this.assets = {};
    this.asset_loading = {};

    this.editor          = null;
    this.scene           = null;

    if(typeof options !== 'undefined'){
      this.initial_options = options;
    }

    if(typeof options.editor !== 'undefined'){
      this.editor = options.editor;
      this.scene  = this.editor.scene;
    }

    if(typeof options.before_init !== 'undefined'){
      this.before_init = options.init;
    } else {
      this.before_init = () => {};
    }

    if(typeof options.after_init !== 'undefined'){
      this.after_init = options.after_init;
    } else {
      this.after_init = () => {};
    }

    this.init();
  }
};

Trollshop.AssetLibrary = class {


  constructor( options ){
    this.name = '';
    this.initial_options = null;
    this.onLoadFunction = null;

    // asset library. keys are asset typeset names and value are objects containing asset obj configurations (keys of second object are filenames)
    this.assets = {};
    this.asset_loading = {};

    this.editor          = null;
    this.scene           = null;

    if(typeof options !== 'undefined'){
      this.initial_options = options;
    }

    if(typeof options.editor !== 'undefined'){
      this.editor = options.editor;
      this.scene  = this.editor.scene;
    }

    if(typeof options.before_init !== 'undefined'){
      this.before_init = options.init;
    } else {
      this.before_init = () => {};
    }

    if(typeof options.after_init !== 'undefined'){
      this.after_init = options.after_init;
    } else {
      this.after_init = () => {};
    }

    this.init();
  }

  onLoad(func){
    this.onLoadFunction = func;
  }

  init(){
    this.before_init();

    this.init_assets(this.after_init);

    if(show_dat_gui){
      this.assets_folder = this.editor.gui.addFolder( "ASSETS" );

      this.assets_debug_folder = this.editor.gui.addFolder( "ASSETS DEBUG" );
    }
  }


  init_assets(done){
    var self = this;

    if(typeof self.initial_options.path !== 'undefined'){

      // load asset lybrary config json
      $.ajax({ 
        url: self.initial_options.path, 
        converters: {
          'text script': function (text) {
            return text;
          }
        },
        success: (response) => {   
          //console.log(response);
          self.config = response;
          self.name = self.config.config.name;
          var type, key = '';

          for ( type in self.config.assets ) {
            self.init_typeset(self.config.assets[type], type);
          }


            /**
          // initially select a random asset from each asset type
          for(var key2 in self.assets){
            //console.log(self);
            if( typeof self.config.assets[key2].initial_asset !== 'undefined'){
              if( self.config.assets[key2].initial_asset === 'random'){
                console.log(key2);
                var asset_keys = [];
                for(var asset_key in self.assets[key2]){
                  asset_keys.push(asset_key);
                }
                var cnt = asset_keys.length;

                if(cnt){
                  var modulo = (cnt>1?(cnt-1):1);
                  var sel_asset_key = parseInt(Math.random()*1000%modulo);
                  //console.log('load asset ' + sel_asset_key + ' from set ' + key2);

                  self.assets[key2][asset_keys[sel_asset_key + '']].load();
                }
              }else{
                self.assets[key2][self.config.assets[key2].initial_asset].load();
              }
            }
          }

*/


          done();
          console.log(this.assets);
        }
      });
    }
  }

  init_typeset( typeset , typename){
    var self = this;
    var mirror_typeset = null;
    var mirror_typename = typename + '_R';
    self.loader                  = new OBJLoader( );
    self.assets[typename]        = {};
    self.asset_loading[typename] = {};

    // find target bone
    this.editor.scene.traverse( function ( child ) {
      if(child.name === typeset.target_bone_name){
        typeset.target_bone = child;
      }
      if(child.name === typeset.mirror_target_bone_name){
        typeset.mirror_target_bone = child;
      }
    });

    // create mirror typeset
    if(typeset.mirror){
      self.assets[typename + '_R']        = {};
      self.asset_loading[typename + '_R'] = {};

      mirror_typeset = JSON.parse(JSON.stringify(typeset));
      mirror_typeset.target_bone = typeset.mirror_target_bone;
      mirror_typeset.initial_asset = typeset.mirror_initial_asset;
      mirror_typeset.target_bone_name = typeset.mirror_target_bone_name;
      self.config.assets[typename + '_R'] = mirror_typeset;
    }


    var i = 0;
    //console.log(typeset);
    for ( var key in typeset.assets ) {

      self.assets[typename][ key ] = new Trollshop.Asset({
        asset_library:      self,
        original_type_name: typename,
        type_name:          typename,
        key:                key,
        typeset:            typeset,
        editor:             self.editor,
      });

      if(typeset.mirror){

        //console.log(mirror_typeset);


        self.assets[mirror_typename][ key ] = new Trollshop.Asset({
          asset_library:      self,
          original_type_name: typename,
          type_name:          mirror_typename,
          key:                key,
          typeset:            mirror_typeset,
          editor:             self.editor,
          after_attach:       function() {

            this.mesh.updateMatrix();

            var mS = (new THREE.Matrix4()).identity();
            mS.elements[0] = -1;
            //mS.elements[10] = -1;

            this.mesh.applyMatrix(mS);
            this.mesh.updateMatrix();
          }
        });

      }
    }


    if(show_dat_gui){
      if(typeset.mirror){

        var mirror_asset_folder = self.assets_folder.addFolder( mirror_typename );
        i = 0;

        for ( key in self.assets[mirror_typename] ) {
          mirror_asset_folder.add(this.assets[mirror_typename][key], 'load');
          mirror_asset_folder.__controllers[i].name( key );
          i++;
        }
      }

      var asset_folder = self.assets_folder.addFolder( typename );

      i = 0;
      for ( key in self.assets[typename] ) {
        asset_folder.add(this.assets[typename][key], 'load');
        asset_folder.__controllers[i].name( key );
        i++;
      }
      asset_folder.add({clear:() => {
        console.log('CLEAR');
        console.log(self);
        self.clear_bone(typeset.target_bone, typename );
      }}, 'clear');
      asset_folder.__controllers[i].name( 'none' );
    }
  }


  // remove all mesh object children from given bone
  clear_bone( target_bone , type_name , model){

    console.log(target_bone);
    console.log(type_name);

    if(target_bone){
      if(typeof this.editor.current_model.bone_slots[type_name] !== 'undefined'){
        this.editor.current_model.bone_slots[type_name].items = [];
      }

      for(var i = 0;i < target_bone.children.length;i++){
        if(
        target_bone.children[i].isMesh &&
          typeof target_bone.children[i].asset_obj !== 'undefined' &&
          (
            target_bone.children[i].asset_obj.type_name == type_name ||
            target_bone.children[i].asset_obj.type_name + 'R' == type_name ||
            target_bone.children[i].asset_obj.type_name + '_R' == type_name
          )
      ){
          console.log(target_bone.children[i].asset_obj.type_name);
          target_bone.remove(target_bone.children[i]);
        }
      }
    }
  }
};

Trollshop.Editor = class {


  constructor( options ){


    this.mouse = new THREE.Vector2();
    this.raycaster = new THREE.Raycaster();

    //document.addEventListener( 'mousemove', this.onDocumentMouseMove, false );
    //document.addEventListener( 'mousedown', (ev) => {this.onclick(ev,this);}, false );
    this.transform_controls = null;

    this.models = [];
    this.current_model = null;

    this.asset_libraries = [];
    this.current_asset_library = null;


    this.current_material = 2;


    this.init();
    this.renderloop();
  }

  onDocumentMouseMove( event ) {

    event.preventDefault();

    this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    this.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

  }


  add_model( model ){
    this.models.push( model );
    this.current_model = model;
  }

  add_asset_library( asset_library ){
    this.asset_libraries.push( asset_library );
    this.current_asset_library = asset_library;
  }

  init(){
    var self = this;

    if(show_dat_gui){
      this.gui = new dat.GUI();
    }

    this.scene = new THREE.Scene();

    this.renderer = new THREE.WebGLRenderer({
      preserveDrawingBuffer: true,
      antialias:             true,
      alpha:                 true,
      canvas:                document.getElementById('trollshop'),
    });
    this.renderer.gammaInput = true;
    this.renderer.gammaOutput = true;
    this.renderer.toneMapping = THREE.Uncharted2ToneMapping;
    this.renderer.toneMappingExposure = 0.75;
    this.renderer.setClearColor(0x555555, 1 );

    var width  = $('#trollshop_container').width();
    var height = $('#trollshop_container').height();
    this.renderer.setSize( width, height );
    this.add_camera();

    this.add_lights();

    this.controls = new OrbitControls( this.camera, this.renderer.domElement);
    this.controls.target.set( 0, 15, 0 );
    this.controls.update();

    this.exporter = new STLExporter();
    //this.exporter = new THREE.OBJExporter();
    //this.exporter = new THREE.GLTFExporter();

    this.clock = new THREE.Clock();

    this.add_transform_controls();

    window.onresize = () => {

      //container = document.getElementById('container');
      //renderer.setSize($(container).width(), $(container).height());
      //container.appendChild(renderer.domElement);

      var width  = $('#trollshop_container').width();
      var height = $('#trollshop_container').height();
      console.log(width + ' ' + height);
      self.renderer.domElement.width  = width;
      self.renderer.domElement.height = height;
      $(self.renderer.domElement).css('width',width + 'px').css('height', height + 'px');

      setTimeout(function() {
        if (self.renderer.domElement.clientHeight != height || self.renderer.domElement.clientWidth != width) {
          height = self.renderer.domElement.clientHeight;
          width  = self.renderer.domElement.clientWidth;

          self.renderer.setViewport(0, 0, self.renderer.domElement.clientWidth, self.renderer.domElement.clientHeight);
          self.camera.aspect = self.renderer.domElement.clientWidth / self.renderer.domElement.clientHeight;
          self.camera.updateProjectionMatrix();
        }
      }, 500);
    };

  }

  export_stl(){
    console.log(this.models[0].scene);

    var export_scene = this.models[0].scene;
    var export_mesh = null;

    var remove_objs_by_type = [
      'TransformControlsGizmo',
      'PointLight',
      'AmbientLight',
      'Object3D',
    ];
    var export_type = 'Group';
    for(var i = 0;i < export_scene.children.length;i++){
        console.log('CHECK ' + export_scene.children[i].type);

      if(export_scene.children[i].type === export_type){
        console.log('Found ' + export_scene.children[i].type);
        export_mesh = export_scene.children[i];
      }
    }
    console.log(export_mesh);

//    export_mesh.traverse(function(obj){
////      if ( typeof obj.geometry !== 'undefined' && obj.geometry.isBufferGeometry ) {
////        var buf_geo = new THREE.Geometry().fromBufferGeometry( obj.geometry );
////        console.log(buf_geo.skinIndices);
////      }
////      if ( typeof obj.geometry !== 'undefined' ) {
////        console.log(obj.geometry.skinIndices);
////      }
//      console.log(obj);
//      console.log(obj.type);
//    });

    var result = this.exporter.parse( export_mesh, {
      //binary: true
    });

    var link = document.createElement( 'a' );
    link.style.display = 'none';
    document.body.appendChild( link );
    link.href = URL.createObjectURL( new Blob( [ result ], { type: 'text/plain' } ) );
    link.download = 'test.stl';
    link.click();


  }

  switch_material(material_id){

  }

  get_material(){
    var self = this;
    var imgTexture = new THREE.TextureLoader().load( "/bower_components/threejs/examples/textures/planets/moon_1024.jpg" );
    imgTexture.wrapS = imgTexture.wrapT = THREE.RepeatWrapping;
    imgTexture.anisotropy = 16;
    imgTexture = null;

    //var diffuseColor = new THREE.Color().setHSL( 0.29, 0.3, 0.4 );
    var diffuseColor = new THREE.Color().setHSL( 0.7, 0.4, 0.6 );

    this.current_material = 3;
    switch(this.current_material){
      case 2:
        //console.log('neue TEXTUR');


        return new THREE.MeshLambertMaterial( {
          map: imgTexture,
          color: diffuseColor,
          reflectivity: 0.8,
          skinning: true,
        } );
      case 3:
          
          console.log('make MATERIAL');
          console.log(this);

            return new THREE.MeshStandardMaterial( {
              skinning: true,
              color: diffuseColor,
              metalness: 0.15,
              roughness: 0.5,
            } );

      case 4:
        return new THREE.MeshBasicMaterial( {
          skinning: true,
          color: 0x156289,
          //flatShading: true
        } );
      default:
      case 1:
        return new THREE.MeshPhongMaterial( {
          skinning: true,
          color: 0x156289,
          emissive: 0x072534,
          side: THREE.DoubleSide,
          //flatShading: true
        } );
    }
  }


  add_camera(){

    //this.camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 0.001, 1000 );
    //this.camera = new THREE.OrthographicCamera( window.innerWidth / - 2, window.innerWidth / 2, window.innerHeight / 2, window.innerHeight / - 2, 0.01, 1000 );
    this.camera = new THREE.PerspectiveCamera(55, window.innerWidth/window.innerHeight, 0.01, 900);

    

    //this.camera.rotation.x = -2.8;
    //this.camera.rotation.y = 0.7;
    //this.camera.rotation.z = 2.9;
    this.camera.zoom = 1.7;
    //this.camera.position.x = 214;
    //this.camera.position.y = -90;
    //this.camera.position.z = 450;
    this.camera.position.set( 0, 20, 80 );
    //target is determined by orbit controls
    //this.camera.lookAt( 0, 20, 0 );
    this.camera.aspect = this.renderer.domElement.clientWidth / this.renderer.domElement.clientHeight;
    this.camera.updateProjectionMatrix();

    //this.camera.zoom = 1;
    //this.camera.position.x = 0;
    //this.camera.position.y = 50;
    //this.camera.position.z = 80;
    //this.camera.updateProjectionMatrix();
    //this.camera.position.y = 0;

  }

  add_lights(){

    var lights = [];
    lights[ 0 ] = new THREE.PointLight( 0xabd5ff, 1, 0 );
    lights[ 1 ] = new THREE.PointLight( 0xff7f10, 1, 0 );
    lights[ 2 ] = new THREE.PointLight( 0xc35c13, 1, 0 );

    lights[ 0 ].position.set( 0, 200, 50 );
    lights[ 1 ].position.set( 100, 200, 100 );
    lights[ 2 ].position.set( - 100, 200, - 100 );

    this.scene.add( lights[ 0 ] );
    this.scene.add( lights[ 1 ] );
    this.scene.add( lights[ 2 ] );

    var particleLight = new THREE.Mesh( new THREE.SphereBufferGeometry( 300, 8, 8 ), new THREE.MeshBasicMaterial( { color: 0xff9999 } ) );
    //this.scene.add( particleLight );
    // Lights
    this.scene.add( new THREE.AmbientLight( 0x333333 ) );

  }

  onclick(ev,self){

    var objects = self.models;
    self.mouse.x = ( ev.clientX / window.innerWidth ) * 2 - 1;
    self.mouse.y = - ( ev.clientY / window.innerHeight ) * 2 + 1;

    self.raycaster.setFromCamera( self.mouse, self.camera );

    var intersections = self.raycaster.intersectObjects( objects, true );

    if ( intersections.length > 0 ) {

      var object = intersections[ 0 ].object;


      //object.material.emissive.set( 0x000000 );

        object.material.emissive.set( 0xaaaaaa );

    }

  }

  renderloop(){
    var self = this;
    var dt = this.clock.getDelta();
    if ( this.current_model !== null && this.current_model.mixer ) this.current_model.mixer.update( dt );

    requestAnimationFrame( () => { self.renderloop.call(self); } );

    //for(var i = 0; i < this.objects.length; i++){
    //  console.log(this.objects[i]);
    //  this.objects[i].loop.call(this.objects[i]);
    //}
    

    this.renderer.render( this.scene, this.camera );
  }


  add_transform_controls() {
    var self = this;

    this.transform_controls = new TransformControls( this.camera, this.renderer.domElement );
    this.transform_controls.addEventListener( 'change', ()=>{
      console.log('transform controls change event');
      //self.renderloop.call(self);
      self.renderer.render( self.scene, self.camera );
    } );
    this.transform_controls.addEventListener( 'dragging-changed', function ( event ) {
      console.log('transform controls dragging-changed event');
      self.controls.enabled = ! event.value;

      console.log(this.object);
      var transform = {
        position: this.object.position,
        rotation: {
          x: this.object.rotation._x,
          y: this.object.rotation._y,
          z: this.object.rotation._z,
        },
        scale: this.object.scale,
      };
      console.log(JSON.stringify(transform));
    } );

    this.scene.add( this.transform_controls );

    window.addEventListener( 'keydown', function ( event ) {
      switch ( event.keyCode ) {
        case 87: // W
          self.transform_controls.setMode( "translate" );
          break;
        case 69: // E
          self.transform_controls.setMode( "rotate" );
          break;
        case 82: // R
          self.transform_controls.setMode( "scale" );
          break;
      }
    });
  }

  remove_transform_controls() {

  }

  add_gui_debug_folder( item , asset , label ){


    asset.asset_library.assets_debug_folder.removeFolder( label );

    var folder = asset.asset_library.assets_debug_folder.addFolder(  label  );

    folder.add( item.position, 'x', parseFloat(- 4 + item.position.x), parseFloat(4 + item.position.x) );
    folder.add( item.position, 'y', parseFloat(- 4 + item.position.y), parseFloat(4 + item.position.y) );
    folder.add( item.position, 'z', parseFloat(- 4 + item.position.z), parseFloat(4 + item.position.z) );

    folder.add( item.rotation, 'x', - Math.PI, Math.PI );
    folder.add( item.rotation, 'y', - Math.PI, Math.PI );
    folder.add( item.rotation, 'z', - Math.PI, Math.PI );

    folder.add( item.scale, 'x', 0, 3 );
    folder.add( item.scale, 'y', 0, 3 );
    folder.add( item.scale, 'z', 0, 3 );

    folder.__controllers[ 0 ].name( "position.x" );
    folder.__controllers[ 1 ].name( "position.y" );
    folder.__controllers[ 2 ].name( "position.z" );

    folder.__controllers[ 3 ].name( "rotation.x" );
    folder.__controllers[ 4 ].name( "rotation.y" );
    folder.__controllers[ 5 ].name( "rotation.z" );

    folder.__controllers[ 6 ].name( "scale.x" );
    folder.__controllers[ 7 ].name( "scale.y" );
    folder.__controllers[ 8 ].name( "scale.z" );

    folder.add( asset, 'transform_controls' );
    folder.__controllers[ 9 ].name( "transform controls" );

    return folder;

  }

  // remove all mesh object children from given bone
  clear_bone( target_bone , type_name , model){

    console.log(target_bone);
    console.log(type_name);

    if(target_bone){
      if(typeof this.current_model.bone_slots[type_name] !== 'undefined'){
        this.current_model.bone_slots[type_name].items = [];
      }

      for(var i = 0;i < target_bone.children.length;i++){
        if(
        target_bone.children[i].isMesh &&
          typeof target_bone.children[i].asset_obj !== 'undefined' &&
          (
            target_bone.children[i].asset_obj.type_name == type_name ||
            target_bone.children[i].asset_obj.type_name + 'R' == type_name ||
            target_bone.children[i].asset_obj.type_name + '_R' == type_name
          )
      ){
          console.log(target_bone.children[i].asset_obj.type_name);
          target_bone.remove(target_bone.children[i]);
        }
      }
    }
  }

  loadModel( id, options){

    if(!id)id = 0

    if(typeof options === 'undefined'){
      options = {};
    }

    if(localStorage.getItem('save_states')){
      let save_states = JSON.parse(localStorage.getItem('save_states'));

      if(typeof save_states[id] !== 'undefined'){
        //Todo: load basemodel
        let basemodel = this.models[0];
        basemodel.removeAllAssets();
        for(var i = 0;i < save_states[id].items.length;i++){



          if(typeof options.assets_only === 'undefined' || !options.assets_only){
            basemodel.loadPose(save_states[id].items[i]);
          }

          if(typeof options.pose_only === 'undefined' || !options.pose_only){
            basemodel.loadAssets(save_states[id].items[i]);
          }
        }
      }
    }
  }
};



//trollshop.load_base_mesh('/testfiles/base_armature_test_1.1.gltf', () => {
//
//  trollshop.init_assets.call(trollshop);
//
//});

// TESTCUBE
/*
trollshop.add_object( new Shopobject({
  init: (self) => {

    self.geometry = new THREE.BoxGeometry( 1, 1, 1 );
    self.material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
    self.testcube = new THREE.Mesh( self.geometry, self.material );
    self.scene.add( self.testcube );

  },
  loop: (self) => {
    console.log(self);
    self.testcube.rotation.x += 0.01;
    self.testcube.rotation.y += 0.01;
  }
}));
*/

export default Trollshop;
