import React, {Component, useState, useEffect} from 'react';

import StripeCheckoutForm from '../StripeCheckoutForm';
import PaypalCheckoutForm from '../PaypalCheckoutForm';

import LoadingIndicator from "../LoadingIndicator";

import Calculation from '../../components/Calculation';
import {Elements, StripeProvider} from 'react-stripe-elements';

import CreditCardIcon from '@material-ui/icons/CreditCard';

import env from './../../../env.js';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';

import RadioGroup from '@material-ui/core/RadioGroup';
import IconButton from '@material-ui/core/IconButton';

import { 
  FormControl,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';


const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

function CheckoutDialog(props){

  const [state,setState] = useState({
    show:                      'payment_method_selection',
    //show:                 'success_message',
    order_id:                  null,
    payment_method:            'stripe',
    coupon_code:               '',
    coupon_error:              '',
    error_message:             '',
    success_message:           '',
    email:                     '',
    email_valid:               false,
    email_error:               false,
    email_error_message:       '',
    tos:                       false,
    tos_error:                 false,
    loading:                   false,
  });

  const onChange = (ev, value) => {
  
    const data = { ...state };

    data[ev.target.name] = ev.target.value;

    setState(data);
  
  }

  const changeEmail = (ev, value) => {

    setState({
      ...state,
      tos_error:false,
      email_error_message:``,
      email_error:false,
      email_valid:false,
      email: ev.target.value
    });
  
  }


  const tosIsValid = (e) => {
		let tos = state.tos;
		if(typeof e !== 'undefined' && typeof e.target !== 'undefined'){
			tos = e.target.checked;
		}
		return {valid:tos, error:!tos};
  }

  const validateTos = (e) => {
    let validation = tosIsValid(e);
    setState({
      ...state,
      tos:validation.valid,
      tos_error:validation.error,
    });
  }

  const emailIsValid = () => {
    let email_error_message = '';
    let email_error = false;
    let email_valid = false;

    if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.email)))
    {
      email_error_message = '';
      email_valid = true;

    }else{

      email_error_message = 'Please enter a valid email address';
      email_error = true;

    }
    return {
      valid: email_valid,
      error: email_error,
      error_message: email_error_message
    };
  }

  const validateEmail = (show_error) => {
    const data = { ...state };

    let validation = emailIsValid();

    data['email_error'] = validation.error;
    data['email_error_message'] = validation.error_message;
    data['email_valid'] = validation.valid;

    setState(data);
  
  }

  const changeCoupon = (ev, value) => {

    setState({
      ...state,
      coupon_code: ev.target.value,
      coupon_error: '',
    });
  
  }

  const submitCouponCode = (e) => {
    let email_validation = emailIsValid();
    let tos_validation = emailIsValid();

    setState({
      ...state,
      email_error:         email_validation.error,
      email_error_message: email_validation.error_message,
      email_valid:         email_validation.valid,
      tos:                 tos_validation.valid,
      tos_error:           tos_validation.error,
    })

    if(e && typeof e.preventDefault !== 'undefined'){
      e.preventDefault();
    }


    fetch(env.site_url + '/coupon/check',{
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        coupon:state.coupon_code,
      })
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          props.setOrderInfo({
            ...props.orderInfo,
            coupon_code:               '',
            refreshCalculationCounter: (props.orderInfo.refreshCalculationCounter+1),
          });
					
					setState({...state, coupon_error: response.error,});
        }else{
          props.setOrderInfo({
            ...props.orderInfo,
            coupon_code:               state.coupon_code,
            refreshCalculationCounter: (props.orderInfo.refreshCalculationCounter+1),
          });
        }
      });
  }

  const downloadStl = () => {
    if(typeof props.downloadStl !== 'undefined'){
      props.downloadStl();
    }
  }

  useEffect(() => {
    if(typeof props.onBeforeUpload !== 'undefined'){
      props.onBeforeUpload();
    }
    props.getStlAsString((data) => {
    
      fetch(env.site_url + '/order/model/upload',{
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model:data,
          model_config:props.model_config,
        })
      })
        .then(res => res.json())
        .then(response => {
          if(typeof props.onUploadReady !== 'undefined'){
            props.onUploadReady();
          }
          if (response.error) {
          }else{
            props.setOrderInfo({
              ...props.orderInfo,
              checksum: response.checksum,
              order_id: response.order_id
            });
          }
        });

    })
  },[]);

  const [calcData,setCalcData] = useState({total:0,rows:[]});

  const refreshCalculation = () => {
    setState({...state, loading:true});
    if(props.orderInfo.order_id){

      fetch(env.site_url + '/order/calculate',{
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          order_id:props.orderInfo.order_id,
          coupon_code:props.orderInfo.coupon_code,
          email:state.email,
          tos:state.tos,
        })
      })
        .then(res => res.json())
        .then(response => {
          setCalcData(response.data);
          setState({...state, loading:false});
          props.setOrderInfo({...props.orderInfo,filename: response.filename});
        });
    }
  };

  useEffect(() => {
    refreshCalculation();
  }, []);

  useEffect(() => {
    refreshCalculation();
  }, [props.orderInfo.order_id,props.orderInfo.refreshCalculationCounter,state.email,state.tos]);


  useEffect(() => {
    if(props.orderInfo.filename){
      setState({...state,show:'success_message'});
    }
  },[props.orderInfo.filename]);

  const handlePaymentMethodSelection = (val) => {
    setState({...state, payment_method: val,});
  }

  const button_next = () => {
    // check required fields
    validateEmail();
		if(!state.tos){
			validateTos();
		}

    if(state.email_valid && state.tos){
      setState({...state, show:state.payment_method + '_checkout_form'});
    }
  };

  return (
    <Dialog
      maxWidth='sm'
      fullWidth={true}
      aria-labelledby="customized-dialog-title"
      open={true}
      onClose={props.closeDialog}
    >
      <DialogContent dividers>
				{ state.loading &&
					<LoadingIndicator />
				}
        <Calculation
          calcData={calcData}
        />

        {state.show === 'payment_method_selection' && (
          <div className="payment_method">
						<List>
							<ListItem
              role={undefined} dense button onClick={() => {setState({...state, tos:!state.tos});}}
              style={(
              state.tos_error?{
								border: '2px solid #e53935',
								boxSizing:'border-box',
              }:{})}
              >
								<ListItemIcon>
									<Checkbox
										edge="start"
										checked={state.tos}
										onChange={validateTos}
										disableRipple
										inputProps={{ 'aria-labelledby': 'tos_checkbox_label' }}
									/>
								</ListItemIcon>
								<ListItemText
									id={'tos_checkbox_label'}
									disableTypography={true}
									style={{
										fontSize:'0.8em',

									}}
								>
									I have read, understood and accepted the <a href="/tos" target="_blanc">Terms of service</a>, <a href="/pp" target="_blanc">privacy policy</a> and <a href="/cp" target="_blanc">cookie policy</a>.
								</ListItemText>
							</ListItem>
						</List>
            <Grid
              container
              spacing={4}
              key={'payment_method_selection_1'}
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  id="email"
                  label="Email *"
                  type="text"
                  name="email"
                  value={state.email}
                  onChange={changeEmail}
                  onBlur={validateEmail}
                  helperText={state.email_error_message}
                  error={state.email_error}
                  style={{
                    width: '100%',
                  }}
                />
              </Grid>
            </Grid>
            <form onSubmit={submitCouponCode}>
              <Grid
                container
                spacing={4}
                key={'payment_method_selection_2'}
              >
                <Grid
                  item
                  xs={6}
                >
                  <TextField
                    id="coupon_code"
                    label="Coupon code"
                    type="text"
                    name="coupon_code"
                    value={state.coupon_code}
                    onChange={changeCoupon}
                    style={{
                      width: '100%',
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    flexDirection:'column-reverse',
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      submitCouponCode();
                    }}>
                    Submit coupon code
                  </Button>
								</Grid>
								{ state.coupon_error &&
										<Grid
											item
											xs={12}
											style={{paddingTop:0}}
										>
											<Alert severity="error">{ state.coupon_error }</Alert>
										</Grid>
								}
							</Grid>
            </form>
          <Grid
            container
            spacing={4}
            key={'payment_method_selection_3'}
          >
            <Grid
              item
              xs={12}
            >
                <Typography variant="body1" component="p" style={{marginRight: '5px', }}>
                Payment method
                </Typography>
                <List>
                  <ListItem key={'payment_method_selection_list_item_stripe'} role={undefined} dense button onClick={() => {handlePaymentMethodSelection('stripe')}}>
                    <ListItemIcon>
                      <Radio
                        edge="start"
                        checked={(state.payment_method === 'stripe'?true:false)}
                        inputProps={{ 'aria-labelledby': 'stripe_payment_method_label' }}
                      />
                    </ListItemIcon>
										<ListItemText
											id={'stripe_payment_method_label'}
											disableTypography={true}
											style={{
												display: 'flex',
												align:'center',
												fontSize:'0.7em',
											}}
										>
											 <img style={{marginRight:'5px'}} title="Credit card" src="/images/payment_option_cc.svg" /> powered by stripe
										</ListItemText>
                  </ListItem>
                  <ListItem key={'payment_method_selection_list_item_paypal'} role={undefined} dense button onClick={() => {handlePaymentMethodSelection('paypal')}}>
                    <ListItemIcon>
                      <Radio
                        edge="start"
                        checked={(state.payment_method === 'paypal'?true:false)}
                        inputProps={{ 'aria-labelledby': 'paypal_payment_method_label' }}
                      />
                    </ListItemIcon>
										<ListItemText
											id={'paypal_payment_method_label'}
											disableTypography={true}
											style={{
												display: 'flex',
												align:'center',
												fontSize:'0.7em',
											}}
										>
											 <img style={{marginRight:'5px'}} title="PayPal" src="/images/payment_option_paypal.svg" />
										</ListItemText>
                  </ListItem>
                </List>
            </Grid>
          </Grid>

          </div>
        )}
        {state.show === 'stripe_checkout_form' && (

          <StripeProvider apiKey={env.stripe_public_key}>
            <div className="example">
              <Elements>

                <StripeCheckoutForm
                  order_id={props.orderInfo.order_id}
                  getStlAsString={props.getStlAsString}
                  coupon={(state.coupon_code_approved?state.coupon:'')}
                  onPaymentSuccess={(data) => {
                    console.log('payment success');
                    console.log(data);
                    setState({...state, show:'success_message'}); 
                    props.setOrderInfo({...props.orderInfo, filename:data.filename}); 
                  }}
                />

              </Elements>
            </div>
          </StripeProvider>

        )}
        {state.show === 'paypal_checkout_form' && (


                <PaypalCheckoutForm
									amount={calcData.total}
                  order_id={props.orderInfo.order_id}
                  getStlAsString={props.getStlAsString}
                  coupon={(state.coupon_code_approved?state.coupon:'')}
                  onPaymentSuccess={(data) => {
                    console.log('payment success');
                    console.log(data);
                    setState({...state, show:'success_message'}); 
                    props.setOrderInfo({...props.orderInfo, filename:data.filename}); 
                  }}
									options={{
										clientId: env.paypal_client_id
									}}
                />


        )}

        {state.show === 'success_message' && (

            <Grid
              container
              spacing={4}
              key={'payment_method_selection_2'}
            >
              <Grid
                item
                xs={12}
              >
                <Paper style={{backgroundColor:'#f1ffde'}}>
                  <Typography style={{padding:'20px', textAlign: 'center'}} variant="h1" component="p">
                    Payment successful!
                    <br/>
                    <br/>
                    <Button
                      target="_blanc"
                      href={env.site_url + '/order/model/download/' + props.orderInfo.order_id + '/' + props.orderInfo.checksum + '/' + props.orderInfo.filename}
                      style={{fontSize:'20px', textAlign: 'center'}}
                      variant='contained'
                    >
                      Download STL
                    </Button>
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

        )}
      </DialogContent>
      <DialogActions>
        <Grid
          container
          spacing={2}
          key={'payment_method_selection_2'}
        >
          <Grid
            item
            xs={6}
          >
            <Button onClick={props.closeDialog} variant='contained' color="primary">
              cancel
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}
          >
            {state.show === 'payment_method_selection' && (
              <Button variant="contained" onClick={button_next}>
                <CreditCardIcon style={{marginRight:'10px'}} />
                Next
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default CheckoutDialog;
