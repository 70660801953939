import React,{useState, useEffect} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { 
  Paper,
  Grid,
  Button,
  Slider,
  Typography,
  AppBar,
  Tabs,
  TabPanel,
  Tab,
  IconButton
} from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { GiNuclearBomb, GiBattleMech, GiBattleGear, GiOpenFolder, GiEgyptianWalk, GiSave } from 'react-icons/gi';

import TreeView from '@material-ui/lab/TreeView';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

import AddAssetDialog from './AddAssetDialog.js';


import Asset from './Asset.js';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

var mouseDown = 0;
document.body.onmousedown = function() { 
    mouseDown = 1;
}
document.body.onmouseup = function() {
    mouseDown = 0;
}


const BaseObject = (props) => {

  const classes = useStyles();

	const [expanded, setExpanded] = React.useState('assets');

  const baseobject = props.baseobject;

  const selected = props.selected;
  const setSelected = props.setSelected;

  const handleToggle = (event, nodeIds) => {
    console.log(nodeIds);
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    console.log(event.target);
    console.log(nodeIds);
    setSelected(nodeIds);
  };

	const handleExpand = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

  const [activePoseSlider, setActivePoseSlider] = useState(null);
  const [poseStates, setPoseStates] = useState({});

  const editor              = props.editor;
  const node_id             = props.node_id;
  const objectBrowserUpdateTrigger             = props.objectBrowserUpdateTrigger;
  const triggerObjectBrowserUpdate             = props.triggerObjectBrowserUpdate;

  const updatePoseState = (event, newValue) => {

    if(activePoseSlider){
      var poseStates_tmp = {...poseStates};
      poseStates_tmp[activePoseSlider.name] = newValue;
      baseobject.actions[activePoseSlider.name].weight = newValue;
      setPoseStates(poseStates_tmp);
    }

  };

  const onUpdateActions = () => {

		// init pose states
		var poseStates_tmp = {};
		for(var key in baseobject.actions){
			poseStates_tmp[key] = baseobject.actions[key].weight;
		}
		setPoseStates(poseStates_tmp);
  }

  useEffect(() => {
    console.log('INIT BASEOBJECT');
    console.log(baseobject);
    setExpanded(expanded);

    onUpdateActions();
    baseobject.onUpdateActions = onUpdateActions;

		baseobject.afterLoadPose = () => {console.log(baseobject.get_pose_config());setPoseStates(baseobject.get_pose_config())};
  },[baseobject]);

  const renderAssets = (objects, level_id) => {

    let output = []

    output.push(<AddAssetDialog 
      style={{
        marginBottom: '7px',
        textAlign: 'center',
        width: '100%',
      }}
      editor={editor}
      key={level_id + '_' + 'add_asset'}
      afterClose={() => { if(triggerObjectBrowserUpdate){ triggerObjectBrowserUpdate(); }}}
    />);

    if(typeof objects !== 'undefined' && objects){

      for(var key in objects) {

        let asset_slotname = key

        for(var i = 0; i < objects[asset_slotname].items.length; i++) {

          output.push(<Asset
            nodeId={level_id + '_' + key}
            key={level_id + '_' + key}
            editor={editor}
            editor_object={objects[key].items[i]}
            objectBrowserUpdateTrigger={objectBrowserUpdateTrigger}
            triggerObjectBrowserUpdate={triggerObjectBrowserUpdate}
          />);

        };

      };

    }

    return output;

  }

  const renderPoses = () => {
		console.log('POSES baseobject');
		console.log(baseobject);

    return <Grid
      container
      spacing={4}
      style={{
      }}
    >
      { Object.keys(baseobject.actions).map((key, index) => {
        if(typeof poseStates[key] !== 'undefined'){
          return <Grid
            item
            key={index}
            lg={4}
            md={4}
            xl={4}
            xs={12}
          >
            <Typography id="continuous-slider" gutterBottom>
              {key}
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
              </Grid>
              <Grid item xs>
                <Slider
                  min={0}
                  step={0.01}
                  max={1}
                  value={poseStates[key]}
                  onChange={updatePoseState}
                  aria-labelledby="continuous-slider"
                  name={key}
                  onMouseUp={() => { setActivePoseSlider(null); }}
                  onMouseOver={(event) => {
                    if(!mouseDown){
                      var input_el = event.target.querySelector('input');
                      if(!input_el){

                        input_el = event.target.parentElement.querySelector('input');
                      }
                      setActivePoseSlider(input_el);
                    }
                  }}
                  onMouseDown={(event) => {
                    var input_el = event.target.querySelector('input');
                    if(!input_el){

                      input_el = event.target.parentElement.querySelector('input');
                    }
                    setActivePoseSlider(input_el);
                  }}
                />
              </Grid>
              <Grid item>
              </Grid>
            </Grid>
          </Grid>;
        }
      }) }
    </Grid>;
  };

  return <Grid
    item
    xs={12}
    style={{
      paddingLeft:0,
      paddingRight:0,
    }}
  >
    <Paper
      style={{
        padding: '5px',
      }}
    >
      <Grid
        container
        spacing={4}
      >

      <Grid
        item
        xs={2}
      >
        <GiBattleMech
          style={{ 
            width: '50px', 
            height: '50px' ,
            marginTop: '4px',
          }}
        ></GiBattleMech>
      </Grid>
      <Grid
        item
        xs={8}
      >
        <strong>
          {baseobject.three_object.name}
        </strong>
        <br />
        ({baseobject.three_object.type})

      </Grid>
    </Grid>
    <Grid
      container
      style={{
        paddingTop: '10px',
      }}
    >
      <ExpansionPanel
        key={'poses_' + node_id + '-header'}
        style={{
          width: '100%',
        }}
        expanded={expanded === 'poses'}
				onChange={handleExpand('poses')}	
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={'poses_' + node_id + '-content'}
          id={'poses_' + node_id + '-header'}
        >
          <GiEgyptianWalk
            style={{
              fontSize: '1.5em',
              marginRight: '5px',
            }}
          />
          <Typography className={classes.heading}>
            Poses
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid
            container
          >
						{renderPoses()}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        key={'assets_' + node_id + '-header'}
        style={{
          width: '100%',
        }}
        expanded={expanded === 'assets'}
				onChange={handleExpand('assets')}	
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={'assets_' + node_id + '-content'}
          id={'assets_' + node_id + '-header'}
        >
          <GiBattleGear
            style={{
              fontSize: '1.5em',
              marginRight: '5px',
            }}
          />
          <Typography className={classes.heading}>
            Assets
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid
            container
          >
            { typeof baseobject !== 'undefined' && typeof baseobject.bone_slots !== 'undefined' && renderAssets(baseobject.bone_slots, node_id) }
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  </Paper>
    </Grid>;
}

export default BaseObject;
