import React,{useState, useEffect} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { 
  Paper,
  Grid,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssetPack from './AssetPack.js';

import { GiOpenFolder, GiTrashCan } from 'react-icons/gi';

import save_state_presets from './save_state_presets.js';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const LoadModelDialog = (props) => {

  const classes = useStyles();

  const editor = props.editor;
  const loadModel = props.loadModel;
  const deleteSaveState = props.deleteSaveState;
  const afterClose = props.afterClose;

  const [open, setOpen] = React.useState(false);
  const [save_states, setSaveStates] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    if(typeof afterClose !== 'undefined'){
      afterClose();
    }
  };

  useEffect(() => {
    if(open){
			if(localStorage.getItem('save_states')){

				setSaveStates(JSON.parse(localStorage.getItem('save_states')));
			}else{
				setSaveStates(save_state_presets);
				localStorage.setItem('save_states', JSON.stringify(save_state_presets));
			}

    }
  },[open]);


  return <React.Fragment>
    <IconButton
      onClick={handleClickOpen}
    >
      <GiOpenFolder />
    </IconButton>
    <Dialog
    fullWidth={true}
    maxWidth={'md'}
    open={open}
    onClose={handleClose}
    aria-labelledby="max-width-dialog-title"
  >
    <DialogTitle id="max-width-dialog-title">Save States</DialogTitle>
    <DialogContent>
      <Grid
        container
        spacing={2}
      >
        { save_states && save_states.map((item, index) => {
          return <Grid
            item
            lg={2}
            md={2}
            xl={3}
            xs={12}
            key={"save_state_" + index}
          >
            <Paper>
              <img
								src={item.thumbnail}
								style={{ cursor: 'pointer', maxWidth: '100%' }}
								alt=""
                onClick={() => { handleClose(); loadModel(index); }}
							/>
              <br/>
              <Button
                onClick={() => { handleClose(); loadModel(index); }}
                style={{
                  height: '40px',
                }}
              >
                <GiOpenFolder
                  style={{
                    fontSize: '2em',
                  }}
                ></GiOpenFolder>
              </Button>
              <Button
                onClick={() => { handleClose(); deleteSaveState(index); }}
                style={{
                  height: '40px',
                }}
              >
                <GiTrashCan
                  style={{
                    fontSize: '2.4em',
                    paddingBottom: '0.14em',
                  }}
                ></GiTrashCan>
              </Button>
            </Paper>
          </Grid>;
        }) }
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
</React.Fragment>;
}

export default LoadModelDialog;
