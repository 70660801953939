import React,{useState, useEffect} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { 
  Grid,
  IconButton,
  Button,
  Slider,
  Typography,
  AppBar,
  Tabs,
  TabPanel,
  Tab,
} from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BaseObject from './BaseObject.js';
import save_state_presets from './save_state_presets.js';

import { GiSave } from 'react-icons/gi';

import TreeView from '@material-ui/lab/TreeView';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import LoadModelDialog from './LoadModelDialog.js';

//var save_state_presets = require('./save_state_presets.js');

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

let initial_poses_loaded = false;

const ObjectBrowser = (props) => {

  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(["0_0_boot_R", "0_0_boot", "0_0_knee_R", "0_0_knee", "0_0_pants", "0_0_shield", "0_0_hand_weapon", "0_0_shoulder_R", "0_0_shoulder", "0_0_torso", "0_0_beard", "0_0_hat", "0_0_head", "0_0"]);
  const selected = props.selected;
  const setSelected = props.setSelected;

  const handleToggle = (event, nodeIds) => {
    console.log(nodeIds);
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    console.log(event.target);
    console.log(nodeIds);
    setSelected(nodeIds);
  };

  const currentAssetLibrary = props.currentAssetLibrary;
  const editor = props.editor;
  const models = props.models;
  const basemeshs = props.basemeshs;
  const selectedModel = props.selectedModel;
  const objectBrowserUpdateTrigger = props.objectBrowserUpdateTrigger;
  const triggerObjectBrowserUpdate = props.triggerObjectBrowserUpdate;

  useEffect(() => {
			if(!localStorage.getItem('save_states')){
				localStorage.setItem('save_states', JSON.stringify(save_state_presets));
			}
  },[]);

  useEffect(() => {
    console.log('INIT OBJECT BROWSER');
    console.log(editor);
    setExpanded(expanded);

  },[models]);

  const reset_camera = () => {
    editor.camera.position.x = 0;
    editor.camera.position.y = 20;
    editor.camera.position.z = 80;
    editor.camera.rotation.x = 0;
    editor.camera.rotation.y = 0;
    editor.camera.rotation.z = 0;
    editor.camera.zoom = 1.7;
    editor.camera.updateProjectionMatrix();
    editor.controls.update();
  }

  const create_thumbnail = (done) => {
    reset_camera();

    setTimeout(() => {
      var dataURL = document.getElementById('trollshop').toDataURL("image/png")  
      var canvas = document.createElement('canvas');
      canvas.id     = "tmp_crop_canvas";
      canvas.width  = 400;
      canvas.height = 400;

      var myContext = canvas.getContext('2d');

      var myImage;
      var img = new Image();
      img.src = dataURL;
      img.onload = () => {
        console.log(img.width)
        let smaller_side = img.width;
        if(img.height < img.width){
          smaller_side = img.height;
        }
        let crop_x = (img.width-smaller_side)/2
        let crop_y = (img.height-smaller_side)/2
        myContext.drawImage(img, crop_x, crop_y,smaller_side,smaller_side,0,0,400,400);
        myContext.save();

        done(canvas.toDataURL())
      };
    }, 200);
  }

	const saveModel = () => {
    let tmp_save_state = {
      items: models.map((item) => item.get_model_config()),
    };
		


    let save_states = JSON.parse(localStorage.getItem('save_states'));
    console.log(save_states)
    console.log(save_state_presets)
    if(!save_states){
      save_states = save_state_presets;
      console.log(save_states)
    }

    create_thumbnail((thumbnail_data_url) => {
      tmp_save_state.thumbnail = thumbnail_data_url;
      localStorage.setItem('save_states', JSON.stringify([ ...save_states, tmp_save_state]));
      console.log(tmp_save_state);
    });
	}

  const deleteSaveState = (id) => {
    if(!id)id = 0
    if(localStorage.getItem('save_states')){
      let save_states = JSON.parse(localStorage.getItem('save_states'));

      save_states.splice(id, 1);
      console.log(save_states);
      localStorage.setItem('save_states', JSON.stringify(save_states));
    }
  }

  const loadModel = (id, options) => {
    editor.loadModel(id, options);
  }

  //const loadAssets = (model_config) => {
  //    console.log('load Assets')
  //  for(var key in model_config.assets){
  //    console.log(key)
  //    for(var i = 0;i < model_config.assets[key].length; i++){
  //    console.log(i)
  //      console.log(editor.asset_libraries[0].assets)
  //      editor.asset_libraries[0].assets[model_config.assets[key][i].type_name][model_config.assets[key][i].name].load();
  //    }
  //  }
  //}

  //const loadPose = (model_config) => {
  //  
  //  for(var i = 0;i < model_config.items;i++){

  //  }
  //  var poseStates_tmp = {...poseStates};
  //  for(var key in model_config.poseStates){
  //    poseStates_tmp[key] = model_config.poseStates[key];
  //    baseobject.actions[key].weight = model_config.poseStates[key];
  //  }
  //  setPoseStates(poseStates_tmp);
  //}

  const renderBaseObjects = (objects, level_id) => {
    if(typeof objects !== 'undefined' && objects){
      return objects.map((obj, index) => {
        if(obj.three_object){
					let id = level_id + '_' + index;
					//      setExpanded([...expanded, id]);

					return <BaseObject
						editor={editor}
						baseobject={obj}
						index={index}
						level_id={level_id}
						node_id={id}
						key={id}
						objectBrowserUpdateTrigger={objectBrowserUpdateTrigger}
						triggerObjectBrowserUpdate={triggerObjectBrowserUpdate}
					/>
        }
        return null;
      });
    }
    return null;
  }

  return <Grid
    container
    spacing={4}
    style={{
      padding:'20px',
    }}
  >
        <IconButton
          onClick={() => {saveModel()}}
        >
          <GiSave />
        </IconButton>
        <LoadModelDialog
          loadModel={loadModel}
          deleteSaveState={deleteSaveState}
        ></LoadModelDialog>
        <br />
    { typeof models[0] !== 'undefined' && renderBaseObjects(models, 0)}
  </Grid>;
}

export default ObjectBrowser;
