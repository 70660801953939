import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import {FontPicker} from "../../jscad_designer/components/FontPicker";
import LoadingIndicator from "../../jscad_designer/components/LoadingIndicator";
import JscadFormControls from "../../jscad_designer/components/JscadFormControls";
//import {getCode, getJscadIncludes, param_def} from "./jscad_code.js";

import Alert from '@material-ui/lab/Alert';
import { 
  Chip, 
  Grid, 
  Toolbar, 
  TextField,
  Checkbox,
  Button,
  ButtonGroup,
  Typography,
  Select,
  InputAdornment,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
} from '@material-ui/core';

import env from './../../env.js';

import CheckoutDialogValueForValue from '../../jscad_designer/components/CheckoutDialogValueForValue';

const getCode = {}, getJscadIncludes = () => [], param_def = {};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
		width: '100%',
  },
}));

var calledCount = 0;
var last = +new Date,
  deferTimer;



var last_change = +new Date;
const update_throttled = (fn) => {
  var threshhold = 1000;
  last = +new Date;
  setTimeout(function () {
    if (last && +new Date > last + threshhold) {
      fn();
    }
  }, threshhold+5);
};

var nextNameplate = false;

const Designer = (props) => {

  const classes = useStyles();

  const [state, setState] = useState({
    checkout_form_open: false,
    processor_state: 1,
    randomFontTrigger: 1,
  });
  const [loading, setLoading] = useState(false);
  const [processor, setProcessor] = useState(false);
  const [orderInfo, setOrderInfo] = useState({
    order_id:                  0,
    refreshCalculationCounter: 0,
    checksum:                  '',
    coupon_code:               '',
    filename:                  '',
  });
  const [config, setConfig] = useState({
  });

  const getParams = () => {
    return {
      ...config,
      param_def: param_def,
      includes: getJscadIncludes(config),
    };
  }

  useEffect(() => {

    let processor = document.init_openjscad('openjscad_designer_viewer');

    processor.onchange = () => {
      setState({...state, processor_state: processor.getState()});
    };

    setProcessor(processor);

		//setTimeout(() => {processor.viewer.handleResize()},2000);
  },[]);

  const getJscad = () => {
    var script = getCode.toString();
    return script.substring(0,(script.lastIndexOf('}'))).substring((script.indexOf('{')+1));
  }

  const getStlAsString = (cb) => {

    processor.clearOutputFile();
    var blob = processor.currentObjectsToBlob();
    var extension = processor.selectedFormatInfo().extension;

    const reader = new FileReader();

    reader.addEventListener('loadend', (e) => {
      const data = e.srcElement.result;

      cb(data);
    });

    reader.readAsText(blob);
  };


  const update = () => {
    //console.log(getCode());
    if(processor){
      processor.abort();
      processor.setJsCad(getJscad(), 'filename', getParams());
    };
  };



  useEffect(() => {
    
    fetch('/assets/jscad_code.js')
      .then((response) => {
        console.log(response);
        return response;
      })
  },[]);

  useEffect(() => {
//if(processor)processor.viewer.handleResize()
    update_throttled(update);
  },[config]);



  return (
    <div className={classes.root}>
      { loading &&
        <LoadingIndicator />
      }
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={4}
          md={4}
          xl={4}
          xs={12}
        >
          <JscadFormControls
            match={props.match}
            setConfig={setConfig}
            config={config}
            processor={processor}
            processor_state={state.processor_state}
            param_def={param_def}
          />
          <Grid
            container
            spacing={4}
            key={'nametag_checkout_info'}
            style={{marginTop:'16px'}}
          >
            {/*
            <Grid
              item
              xs={12}

            >
              <Alert severity="info" style={{fontSize:'.9em', fontWeight:'bold'}} >Use coupon code "BETA_02_2020" for FREE nametags.</Alert>
            </Grid>
            */}
            <Grid
              item
              xs={12}

            >
              <Button onClick={() => {
                setOrderInfo({
                  order_id:    0,
                  checksum:    '',
                  coupon_code: '',
                  filename:    '',
                });
                setLoading(true);
                setTimeout(() => {
                  setState({...state, checkout_form_open: true});
                }, 100);
              }}
              style={{width: '100%'}}
              variant='contained'
              disabled={(state.processor_state !== 2)}
            >
              <Typography variant="h3" component="p" style={{marginRight: '5px', }}>
                Download STL file
              </Typography>
              <Typography variant="h3" component="p" style={{display:'none',fontSize: '15px',marginRight: '5px', color: '#449721', }}>
                $2.50 
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xl={4}
          xs={12}
					style={{marginTop:'15px', fontSize:'.7em'}}
        >
					known from
          <a target="_blank" href="https://hackaday.com/2020/02/22/3d-printable-nameplates-from-your-web-browser/">
					  <img width="200" src="/images/hackaday_logo.png"/>
					</a>
				</Grid>
      </Grid>
      <Grid
        item
        lg={8}
        md={8}
        xl={8}
        xs={12}
        style={{
          maxWidth: '100%',
          position: 'relative',
        }}
      >
        <div id="header" style={{display:'none'}}>
          <div id="errordiv"></div>
        </div>

        { (state.processor_state !== 2) &&
            <LoadingIndicator />
        }

        <div style={{width:'100%', height:'100%'}} onContextMenu={() => false} id="openjscad_designer_viewer"></div>


        <div id="tail" style={{display:'none'}}>
          <div id="statusdiv"></div>
        </div>

      </Grid>
    </Grid>
    { state.checkout_form_open && 
        <CheckoutDialogValueForValue
          model_config={config}
          orderInfo={orderInfo}
          setOrderInfo={setOrderInfo}
          getStlAsString={getStlAsString}
          closeDialog={() => {
            setState({...state, checkout_form_open: false});
          }}
          onUploadReady={() => {
            setLoading(false);
          }}
        />
    }
  </div>
  );
};

export default Designer;
