import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  //Dashboard as DashboardView,
  Designer as DesignerView,
  DesignerNeu as DesignerNeuView,
  NotFound as NotFoundView,
  Imprint as ImprintView,
  Tos as TosView,
  PrivacyPolicy as PrivacyPolicyView,
  CookiePolicy as CookiePolicyView,
  Home as HomeView,
  ModelEditor as ModelEditorView,
  Account as AccountView,
  SignInSide as SignInView,
} from './views';


const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={ModelEditorView}
        exact
        layout={MainLayout}
        path="/"
      />
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MainLayout}
        path="/Home"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MainLayout}
        path="/signin"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MainLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={ImprintView}
        exact
        layout={MainLayout}
        path="/imprint"
      />
      <RouteWithLayout
        component={CookiePolicyView}
        exact
        layout={MainLayout}
        path="/cp"
      />
      <RouteWithLayout
        component={PrivacyPolicyView}
        exact
        layout={MainLayout}
        path="/pp"
      />
      <RouteWithLayout
        component={TosView}
        exact
        layout={MainLayout}
        path="/tos"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
