import React, { useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import FontPicker from "../FontPicker";


import { 
  Chip, 
  Grid, 
  Toolbar, 
  TextField,
  Checkbox,
  Button,
  ButtonGroup,
  Typography,
  Select,
  InputAdornment,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
} from '@material-ui/core';

//import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded';
import CropSquareRoundedIcon from '@material-ui/icons/CropSquareRounded';
import CheckBoxOutlineBlankSharpIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';
import PanoramaFishEyeSharpIcon from '@material-ui/icons/PanoramaFishEyeSharp';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import Icons from '@material-ui/icons';

import env from './../../../env.js';

var rFontTrigger = 1;

var nextNameplate = false;

//var namelist = [];
//
//namelist = ['Jessica', 'Ashley', 'Brittany', 'Amanda', 'Samantha', 'Sarah', 'Stephanie', 'Jennifer', 'Elizabeth', 'Lauren', 'Megan', 'Emily', 'Nicole', 'Kayla', 'Amber', 'Rachel', 'Courtney', 'Danielle', 'Heather', 'Melissa', 'Rebecca', 'Michelle', 'Tiffany', 'Chelsea', 'Christina', 'Michael', 'Christopher', 'Matthew', 'Joshua', 'Daniel', 'David', 'Andrew', 'James', 'Justin', 'Joseph', 'Ryan', 'John', 'Robert', 'Nicholas', 'Anthony', 'William', 'Jonathan', 'Kyle', 'Brandon', 'Jacob', 'Tyler', 'Zachary', 'Kevin', 'Eric', 'Steven'];
//
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  selectedButton:{
    backgroundColor: '#4f4f4f',
    color: '#fff !important',
  }
}));

function JscadFormControls(props){

  const processor = props.processor;

  const classes = useStyles();

  const name_url_param = props.match.params.name;

  const [state,setState] = useState({});
  const [currentFieldset,setCurrentFieldset] = useState(null);

  const [randomFontTrigger, setRandomFontTrigger] = useState(1);


  const onChange = (ev, value) => {

    const raw_data = { ...state};
    const data = { ...state };

    if(typeof ev.target !== 'undefined'){
      if(ev.target.type === 'checkbox'){
        if(ev.target.checked){
          data[ev.target.name] = true;
        }else{
          data[ev.target.name] = false;
        }
      }else{
        window['globalnt_' + ev.target.name] = ev.target.value;
        raw_data[ev.target.name] = ev.target.value;
        if(ev.target.type === 'number'){
          data[ev.target.name] = parseInt(ev.target.value);
          if(isNaN(data[ev.target.name])){
            data[ev.target.name] = 1;
          }
        }else{
          data[ev.target.name] = ev.target.value;
        }
      }
    }
    props.setConfig(data);
    setState(raw_data);

  }

  //const generateNametag = (names) => {
  //  console.log(rFontTrigger + ' next');
  //  rFontTrigger += 1;
  //  let old_font = props.config.font + '';
  //  let current_name = names.pop();
  //  console.log('RANDOM FONT TRIGGER NEXT ' + (randomFontTrigger+1));
  //  setRandomFontTrigger(rFontTrigger);
  //  setTimeout(() => {
  //    let new_font = props.config.font + '';
  //    console.log('NEXT ' + old_font + ' ' + new_font);
  //    props.setConfig({...props.config, text: current_name, font: window.globalnt_font});

  //    nextNameplate = () => {
  //      //console.log('NEXT NAMEPLATE');
  //      downloadStl(() => {
  //        setTimeout(() => {
  //         generateNametag(names);
  //        }, 1000);
  //      }, current_name + '');
  //    };
  //  }, 1000);
  //}

  //const generateNametags = () => {

  //  generateNametag(namelist);
  //}

  const downloadStl = (cb, name) => {

    processor.clearOutputFile();
    var blob = processor.currentObjectsToBlob();
    var extension = processor.selectedFormatInfo().extension;

    function onDone(data, downloadAttribute, blobMode, noData, blob) {

      processor.hasOutputFile = true;
      processor.downloadOutputFileLink.href = data;
      if (blobMode) {
        processor.outputFileBlobUrl = data;
      } else {
        // FIXME: what to do with this one ?
        // that.outputFileDirEntry = dirEntry // save for later removal
      }
      processor.downloadOutputFileLink.innerHTML = processor.downloadLinkTextForCurrentObject();
      processor.downloadOutputFileLink.setAttribute('download', name + '.stl');
      if (noData) {
        processor.downloadOutputFileLink.setAttribute('target', '_blank');
      }
      processor.enableItems();

      document.getElementsByClassName('downloadOutputFileLink')[0].click()
      cb();
    }

    if (processor.viewedObject) {
      processor._generateOutputFile(extension, blob, onDone, processor);
      if (processor.ondownload) processor.ondownload(processor);
    }
  }

  useEffect(() => {
    //console.log('NEXT NAMEPLATE ' + loading);

    if(nextNameplate && props.processor_state === 2){
      nextNameplate();
      nextNameplate = false;
    }
  },[props.processor_state]);

  const initialize_config = () => {
    var fieldset_set = false;
    var config = {};
    for(var i = 0;i < props.param_def.length;i++){
      if(props.param_def[i].type === 'fieldset'){
				//make first fieldset selected by default

				if(!fieldset_set){
					fieldset_set = true;
					setCurrentFieldset(props.param_def[i].name);
				}

        for(var k = 0;k < props.param_def[i].params.length;k++){
          config[props.param_def[i].params[k].name] = (typeof props.param_def[i].params[k].default !== 'undefined'?props.param_def[i].params[k].default:null);
        }
      }else{
        config[props.param_def[i].name] = (props.param_def[i].default || props.param_def[i].default === 0?props.param_def[i].default:null);
      }
    }
    setState(config);
    props.setConfig(config);
  }

  useEffect(() => {
    //    rFontTrigger += 1;
    //    setRandomFontTrigger(rFontTrigger);
    initialize_config();
  },[]);

  const renderField = (field_cfg) => {
    if(typeof field_cfg.default === 'undefined'){
      field_cfg.default = '';
    }

    if(typeof props.config[field_cfg.name] === 'undefined'){
      props.config[field_cfg.name] = field_cfg.default;
    }
    if(field_cfg.hide && field_cfg.hide(props.config))return null;
    //    console.log('RENDER FIELD');
    //    console.log(field_cfg);
    var grid_cfg = {
      xs:12,
      sm:12,
      lg:12,
      xl:12,
    };

    var default_props = {
      id:       "jsConfigControl_" + field_cfg.name,
      name:     field_cfg.name,
      label:    (typeof field_cfg.label !== 'undefined'?field_cfg.label:field_cfg.name),
      style:    { width: '100%', },
      onChange: onChange,
    };

    var formfield = <TextField
      { ...default_props }
      value={state[field_cfg.name] || ''}
    />;

    //determine field type
    switch(field_cfg.type){
      case 'font':
        grid_cfg = {
          xs:9,
          sm:9,
          lg:9,
          xl:9,
        };
        formfield = <FontPicker
          { ...default_props }
          randomFontTrigger={randomFontTrigger}
        />;
        break;
      case 'integer':
        grid_cfg = {
          xs:6,
          sm:3,
          lg:3,
          xl:2,
        };
        formfield = <TextField
          { ...default_props }
          type="number"
          value={state[field_cfg.name] || 0}
          {...{InputProps:(field_cfg.unit?{ endAdornment: <InputAdornment position="end">{field_cfg.unit}</InputAdornment>, }:null)}}
        />;
        break;
      case 'radio':
        formfield = <FormControl component="fieldset">
          <FormLabel component="legend">{(field_cfg.label?field_cfg.label:field_cfg.name)}</FormLabel>
          <div
            color="default"
            style={{marginTop:'6px',width:'100%',display:'flex',alignItems:'stretch',alignContent:'stretch', flexWrap:'wrap',}}
            aria-label="primary button"
          >
            { field_cfg.options.map((option_cfg) => {
              let presets = {};
              if(typeof option_cfg.presets !== 'undefined'){
                presets = {...option_cfg.presets};
              }
              let config_value = {};
              config_value[field_cfg.name] = option_cfg.value;
              return <Button
                key={ "JscadFormControls_Button_group_" + field_cfg.name + "_button_" + option_cfg.value }
                className={(props.config[field_cfg.name] === option_cfg.value?classes.selectedButton:null)}
                disabled={(props.config[field_cfg.name] === option_cfg.value?true:false)}
                onClick={() => {props.setConfig({...props.config, ...presets, ...config_value});setState({...state, ...presets, ...config_value});}}
                style={{marginLeft:0,}}
              >
                {(typeof option_cfg.icon !== 'undefined'?option_cfg.icon:option_cfg.value)}
            </Button>;
            }) }
          </div>
        </FormControl>;
        break;
      case 'string':
      default:
        break;
    }
    var output = [];

    output.push(<Grid
      item
      {...grid_cfg}

      key={'jscadFormControl_item_' + field_cfg.name}
      style={{paddingTop:0,}}
    >
      { formfield }
    </Grid>);

    if(field_cfg.type === 'font'){

      output.push(<Grid
        item
				sm={3}
        xs={12}
        key={'jscadFormControl_item_' +  field_cfg.name + '_randomfonttrigger'}
      >
        <Button onClick={() => {
          setRandomFontTrigger(randomFontTrigger+1);
        }}
        style={{width: '100%'}}
        variant='contained'
        disabled={(props.processor_state !== 2)}
      >
        Random font
      </Button>
    </Grid>);
    }

    return output;
  }

  const renderFieldset = (fieldset) => {

    let fields = [];

    for(var i = 0;i < fieldset.params.length;i++){
      fields.push(renderField(fieldset.params[i]))
    }

    return [<Grid
      item
      xs={12}
      style={{
        display: (currentFieldset === fieldset.name?'block':'none'),
      }}
      key={'fieldset_' + fieldset.name}
    >
      <Grid
        container
        spacing={4}
        key={'nametag_config_fieldset_items_' + fieldset.name}
      >
        {fields}
      </Grid>
    </Grid>];
  }

  const renderControls = () => {

    let fieldset_tabs = [];

    let fields = [];

    for(var i = 0;i < props.param_def.length;i++){
      if(props.param_def[i].type === 'fieldset'){
        fields = [...fields, ...renderFieldset(props.param_def[i])];

				let isActive = currentFieldset === props.param_def[i].name;
				let current_name = props.param_def[i].name;

				fieldset_tabs.push(<Button
          key={'fieldset_tab_button_' + current_name}
					className={(isActive?classes.selectedButton:null)}
					disabled={(isActive?true:false)}
					startIcon={props.param_def[i].icon}
					style={{fontSize:'10px',}}
					size="s"
					onClick={() => {
						setCurrentFieldset( current_name);
					}}
				>
					{props.param_def[i].label}
				</Button>);
      }else{
        fields.push(renderField(props.param_def[i]))
      }
    }

    return (
      <div key='jscad_form_controls_container'>
        <Grid
          container
          spacing={4}
          key={'nametag_config_fieldset_tabs'}
					style={{marginBottom:'20px'}}
        >
          <Grid
            item
            xs={12}
          >
            <ButtonGroup size="large">
              { fieldset_tabs }
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={4}
          key={'nametag_config_fieldsets'}
        >
          { fields }
        </Grid>
      </div>
    )
  }


  return renderControls();
}

export default JscadFormControls;
