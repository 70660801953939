import React,{useState, useEffect} from 'react';

import { useTracked } from './State.tsx';

import { makeStyles } from '@material-ui/core/styles';
import { 
  Grid,
  Paper,
  Button,
  IconButton,
  Slider,
  Typography,
  AppBar,
  Tabs,
  TabPanel,
  Tab,
} from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BaseObject from './BaseObject.js';


import TreeView from '@material-ui/lab/TreeView';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

import OpenWithIcon from '@material-ui/icons/OpenWith';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import ClearIcon from '@material-ui/icons/Clear';
import { GiTrashCan, GiClockwiseRotation,GiMove,GiTreeGrowth } from 'react-icons/gi';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Asset = (props) => {

  const classes = useStyles();

  const [globalstate, setGlobalstate] = useTracked();

  const selected = props.selected;
  const setSelected = props.setSelected;

  const currentAssetLibrary = props.currentAssetLibrary;
  const editor = props.editor;
  const models = props.models;
  const basemeshs = props.basemeshs;
  const selectedModel = props.selectedModel;
  const objectBrowserUpdateTrigger = props.objectBrowserUpdateTrigger;
  const triggerObjectBrowserUpdate = props.triggerObjectBrowserUpdate;

  const level_id = props.level_id;
  const editor_object = props.editor_object;

  const [transform_controls_active, setTransformControlsActive] = useState(false);
  const [transform_controls_mode, setTransformControlsMode] = useState('translate');

  const getAssetSignature = () => editor_object.type_name + '_' + editor_object.name

  useEffect(() => {
    console.log(editor_object);
  },[]);

  return <Grid
    item
    xs={12}
    style={{
      marginBottom: '5px',
    }}
  >
    <Paper
      style={{
        padding: '5px',
      }}
    >
    <Grid
      container
    >

    <Grid
      item
      xs={2}
    >
      <img src={editor_object.thumbnail} width="50" style={{ maxWidth: '100%', }} alt="" />
    </Grid>
    <Grid
      item
      xs={8}
      style={{
        paddingLeft: '5px',
      }}
    >
      <strong>
        {editor_object.name}
      </strong>
      <br />
      ({editor_object.type_name})
    </Grid>
    <Grid
      item
      xs={12}
    >
      <IconButton
        onClick={() => {
          if(globalstate.transform_controls.asset !== getAssetSignature()){
            editor_object.editor.transform_controls.detach(  );
            editor_object.editor.transform_controls.attach( editor_object.mesh );
            setTransformControlsActive(true)
            setGlobalstate((s) => ({
              ...s,
              transform_controls: {
                ...s.transform_controls,
                active: true,
                asset: getAssetSignature(),
              },
            }));
          }else{
            editor_object.editor.transform_controls.detach(  );
            setTransformControlsActive(false)
            setGlobalstate((s) => ({
              ...s,
              transform_controls: {
                ...s.transform_controls,
                active: false,
                asset: '',
              },
            }));

          }
        }}

        style={{
          backgroundColor: (globalstate.transform_controls.asset === getAssetSignature() && globalstate.transform_controls.active ? '#777':''),
          color: (globalstate.transform_controls.asset === getAssetSignature() && globalstate.transform_controls.active ? '#ddd':''),
        }}
      >
        <GiMove />

      </IconButton>
      <IconButton
        onClick={() => {
          editor.clear_bone(editor_object.typeset.target_bone, editor_object.type_name)

          triggerObjectBrowserUpdate();
        }}
      >

      <GiTrashCan
        style={{
          paddingBottom: '3px',
        }}
      />

      </IconButton>
    </Grid>
    { globalstate.transform_controls.asset === getAssetSignature() && globalstate.transform_controls.active &&
    <Grid
      item
      xs={12}
      style={{
        paddingTop: '5px',
      }}
    >
      <IconButton
        onClick={() => {
          editor_object.editor.transform_controls.setMode('translate');
          setGlobalstate((s) => ({
            ...s,
            transform_controls: {
              ...s.transform_controls,
              mode: 'translate',
            },
          }));
        }}

        style={{
          backgroundColor: (globalstate.transform_controls.mode === 'translate' ? '#777':''),
          color: (globalstate.transform_controls.mode === 'translate' ? '#ddd':''),
        }}
      >
        <GiMove />
      </IconButton>
      <IconButton
        onClick={() => {
          editor_object.editor.transform_controls.setMode('rotate');
          setGlobalstate((s) => ({
            ...s,
            transform_controls: {
              ...s.transform_controls,
              mode: 'rotate',
            },
          }));
        }}

        style={{
          backgroundColor: (globalstate.transform_controls.mode === 'rotate' ? '#777':''),
          color: (globalstate.transform_controls.mode === 'rotate' ? '#ddd':''),
        }}
      >
        <GiClockwiseRotation />
      </IconButton>
      <IconButton
        onClick={() => {
          editor_object.editor.transform_controls.setMode('scale');
          setGlobalstate((s) => ({
            ...s,
            transform_controls: {
              ...s.transform_controls,
              mode: 'scale',
            },
          }));
        }}

        style={{
          backgroundColor: (globalstate.transform_controls.mode === 'scale' ? '#777':''),
          color: (globalstate.transform_controls.mode === 'scale' ? '#ddd':''),
        }}
      >
        <GiTreeGrowth />
      </IconButton>
      <IconButton
        onClick={() => {
          editor_object.editor.transform_controls.detach(  );
          setGlobalstate((s) => ({
            ...s,
            transform_controls: {
              ...s.transform_controls,
              active: false,
              asset: '',
            },
          }));
        }}
      >
        <ClearIcon />
      </IconButton>
    </Grid>
    }
  </Grid>
  </Paper>
</Grid>;
}

export default Asset;
