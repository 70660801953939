import { useState } from 'react';

import { createContainer } from 'react-tracked';

const initialState = {
  transform_controls:
  {
    active: 0,
    asset: "",
    mode: "translate",
  }
};

const useValue = () => useState(initialState);

export const { Provider, useTracked } = createContainer(useValue);
