import React, {Component, useState, useEffect} from 'react';
import StripeCheckoutForm from '../StripeCheckoutForm';
import {Elements, StripeProvider} from 'react-stripe-elements';

import CreditCardIcon from '@material-ui/icons/CreditCard';

import env from './../../../env.js';

import { 
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

function Calculation(props){

  const [state,setState] = useState({
    order_id:             props.order_id,
    coupon_code:          '',
    coupon_code_approved: false,
  });


	const calcData = props.calcData;

  const padding = {
    paddingTop:    '5px',
    paddingBottom: '5px',
  };

  return(
    <Grid
      container
      spacing={4}
      key={'calculation_table_container'}
    >
      <Grid
        item
        xs={12}
      >
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{...padding,}}>name</TableCell>
                <TableCell align="right" style={{...padding,}}>price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calcData.rows.map(row => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row" style={(row.style?{...row.style, ...padding}:{...padding})}>
                    {row.name}
                  </TableCell>
                  <TableCell align="right" style={(row.style?{...row.style, ...padding}:{...padding})}>$ {row.price.toFixed(2)}</TableCell>
                </TableRow>
              ))}
              <TableRow key='calculationTableTotalRow'>
                <TableCell component="th" align="right" style={{...padding, fontWeight:'bold'}}>
                  TOTAL
                </TableCell>
                <TableCell align="right" style={{...padding, fontWeight:'bold'}}>$ {calcData.total.toFixed(2)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default Calculation;
