import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import FontPicker from "../../jscad_designer/components/FontPicker";
import LoadingIndicator from "../../jscad_designer/components/LoadingIndicator";


import { 
  Chip, 
  Grid, 
  Toolbar, 
  TextField,
  Checkbox,
  Button,
  ButtonGroup,
  Typography,
  Select,
  InputAdornment,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
} from '@material-ui/core';

//import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded';
import CropSquareRoundedIcon from '@material-ui/icons/CropSquareRounded';
import CheckBoxOutlineBlankSharpIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';
import PanoramaFishEyeSharpIcon from '@material-ui/icons/PanoramaFishEyeSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';

import env from './../../env.js';

import CheckoutDialog from '../../jscad_designer/components/CheckoutDialog';

//var namelist = [ 'Emma', 'Olivia', 'Ava', 'Isabella', 'Sophia', 'Charlotte', 'Mia', 'Amelia', 'Harper', 'Evelyn', 'Abigail', 'Emily', 'Elizabeth', 'Mila', 'Ella', 'Avery', 'Sofia', 'Camila', 'Aria', 'Scarlett', 'Victoria', 'Madison', 'Luna', 'Grace', 'Chloe', 'Penelope', 'Layla', 'Riley', 'Zoey', 'Nora', 'Lily', 'Eleanor', 'Hannah', 'Lillian', 'Addison', 'Aubrey', 'Ellie', 'Stella', 'Natalie', 'Zoe', 'Leah', 'Hazel', 'Violet', 'Aurora', 'Savannah', 'Audrey', 'Brooklyn', 'Bella', 'Claire', 'Skylar', 'Liam'];
var namelist = [ 'Emma', 'Olivia', 'Ava', 'Isabella', 'Sophia', 'Charlotte', 'Mia', 'Amelia', 'Harper', 'Evelyn', 'Abigail', 'Emily', 'Elizabeth', 'Mila', 'Ella', 'Avery', 'Sofia', 'Camila', 'Aria', 'Scarlett', 'Victoria', 'Madison', 'Luna', 'Grace', 'Chloe', 'Penelope', 'Layla', 'Riley', 'Zoey', 'Nora', 'Lily', 'Eleanor', 'Hannah', 'Lillian', 'Addison', 'Aubrey', 'Ellie', 'Stella', 'Natalie', 'Zoe', 'Leah', 'Hazel', 'Violet', 'Aurora', 'Savannah', 'Audrey', 'Brooklyn', 'Bella', 'Claire', 'Skylar', 'Liam', 'Noah', 'William', 'James', 'Oliver', 'Benjamin', 'Elijah', 'Lucas', 'Mason', 'Logan', 'Alexander', 'Ethan', 'Jacob', 'Michael', 'Daniel', 'Henry', 'Jackson', 'Sebastian', 'Aiden', 'Matthew', 'Samuel', 'David', 'Joseph', 'Carter', 'Owen', 'Wyatt', 'John', 'Jack', 'Luke', 'Jayden', 'Dylan', 'Grayson', 'Levi', 'Isaac', 'Gabriel', 'Julian', 'Mateo', 'Anthony', 'Jaxon', 'Lincoln', 'Joshua', 'Christopher', 'Andrew', 'Theodore', 'Caleb', 'Ryan', 'Asher', 'Nathan', 'Thomas', 'Leo', ];

var namelist = [ 'Sarah', 'Kate', 'Emma', 'Alicia', 'Amber', 'Angie', 'Belinda', 'Kylie', 'Lisa', 'Melanie', 'Melissa', 'Natalie', 'Sally', 'Samantha', 'Stephanie', 'Mark', 'David', 'Matt', 'Chris', 'James', 'Matthew', 'Andrew', 'Anthony', 'Ben', 'Brad', 'Luke', 'Paul', 'Stephen' ];

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  selectedButton:{
    backgroundColor: '#4f4f4f',
    color: '#fff !important',
  }
}));

var calledCount = 0;
var last = +new Date,
  deferTimer;

const throttle = (fn, threshhold, scope) => {
  threshhold || (threshhold = 250);
  return function () {
    var context = scope || this;

    var now = +new Date,
      args = arguments;
    if (last && now < last + threshhold) {
      // hold on to it
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        fn.apply(context, args);
      }, threshhold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
}

var nextNameplate = false;
var rFontTrigger = 1;

const DesignerNeu = (props) => {
  const classes = useStyles();

  const name_url_param = props.match.params.name;

  const [state, setState] = useState({
    checkout_form_open: false,
    processor_state: 1,
    randomFontTrigger: 1,
  });
  const [loading, setLoading] = useState(false);
  const [processor, setProcessor] = useState(false);
  const [randomFontTrigger, setRandomFontTrigger] = useState(1);
  const [orderInfo, setOrderInfo] = useState({
    order_id:                  0,
    refreshCalculationCounter: 0,
    checksum:                  '',
    coupon_code:               '',
    filename:                  '',
  });
  const [config, setConfig] = useState({
    text:               (name_url_param?name_url_param:'Your Name'),
    font:               'abel',
    font_index:         0,
    paddingTop:         10,
    paddingRight:       10,
    paddingBottom:      10,
    paddingLeft:        10,
    backplateThickness: 2,
    textThickness:      9,
    backplateShape:     'round_rectangle',
  });
  const getParams = () => {
    return config;
  }

  const getCode = () => {
    return `
include('` + window.location.origin + `/assets/opentype.min.jscad');
include( '` + window.location.origin + `/assets/fonts/` + config.font + `_ttf.jscad');

function main (params) { 
console.log('FROM THE SCRIPT');
console.log(params);
  csgText = build_text_object();

  ` + ( config.backplateShape === 'circle' ? 'let returnObject = createCircleBackplate(csgText);':'') + `
  ` + ( config.backplateShape === 'rectangle' ? 'let returnObject = createBackplate(csgText);':'') + `
  ` + ( config.backplateShape === 'round_rectangle' ? 'let returnObject = createRoundBackplate(csgText);':'') + `
  ` + ( config.backplateShape === 'none' ? 'let returnObject = createTextOnly();':'') + `

  //let returnObject = createCircleBackplate(csgText);

  return returnObject;
}
function createTextOnly(){
let text = build_text_object();
let bounds = text.getBounds();
text = text.translate([(-1 * bounds[1].x/2), (-1 * bounds[1].y/2),0]);

return text;
}

function build_text_object(){

  let font = Font3D.parse(` + config.font + `_ttf_data.buffer);
  let cagText = Font3D.cagFromString(font,params.text, 60);
  let csgText = linear_extrude({ height: ` + config.textThickness + ` }, cagText[0].union(cagText)).setColor(html2rgb('#7286ef'));

  return csgText;
}

function createCircleBackplate(csgText){
  let bounds = csgText.getBounds();

  let cube_x = bounds[1].x - bounds[0].x + ` + (parseInt(config.paddingRight) + parseInt(config.paddingLeft)) + `;
  let cube_y = bounds[1].y - bounds[0].y + ` + (parseInt(config.paddingTop) + parseInt(config.paddingBottom)) + `;
  let radius = bounds[1].x/2 + 10;
  let cube_1 = cylinder({r: radius, h: ` + config.backplateThickness + `, fn: 60});

  //cube_1 = cube_1.center([0,0,0]);
  let cube_bounds = cube_1.getBounds();
  //cube_1 = cube_1.translate([(-1 * cube_bounds[1].x/2 + bounds[0].x), ((-1 * cube_bounds[1].y/2) + bounds[0].y),0]);
  //cube_1 = cube_1.translate([bounds[0].x, bounds[0].y,0]);
  csgText = csgText.translate([(-1 * bounds[1].x/2), (-1 * bounds[1].y/2),0]);
  return [ csgText, cube_1.setColor(html2rgb('#3f51b5'))];
}

function createBackplate(csgText){
  let bounds = csgText.getBounds();

  let cube_x = bounds[1].x - bounds[0].x + ` + (parseInt(config.paddingRight) + parseInt(config.paddingLeft)) + `;
  let cube_y = bounds[1].y - bounds[0].y + ` + (parseInt(config.paddingTop) + parseInt(config.paddingBottom)) + `;
  let cube_1 = cube({size: [cube_x,cube_y,` + config.backplateThickness + `]}).translate([-` + config.paddingLeft + `,-` + config.paddingBottom + `,0]);

  cube_1 = cube_1.center([0,0,0]);
  let cube_bounds = cube_1.getBounds();
  cube_1 = cube_1.translate([(-1 * cube_bounds[1].x/2 + bounds[0].x), ((-1 * cube_bounds[1].y/2) + bounds[0].y),0]);
  csgText = csgText.translate([(-1 * cube_bounds[1].x/2), (-1 * cube_bounds[1].y/2),0]);
  return [ csgText, cube_1.setColor(html2rgb('#3f51b5'))];
}
function createRoundBackplate(csgText){
  let bounds = csgText.getBounds();

  let cube_x = bounds[1].x - bounds[0].x + ` + (parseInt(config.paddingRight) + parseInt(config.paddingLeft)) + `;
  let cube_y = bounds[1].y - bounds[0].y + ` + (parseInt(config.paddingTop) + parseInt(config.paddingBottom)) + `;
  let cube_2 = cube({size: [1000,1000,` + config.backplateThickness + `]}).translate([-500,-500,0]);
  let cube_1 = CSG.roundedCube({radius: [cube_x/2,cube_y/2,10+` + config.backplateThickness + `], roundradius: 10, resolution: 30}).translate([-` + config.paddingLeft + `/2,-` + config.paddingBottom + `/2,0]);

  cube_1 = cube_1.intersect(cube_2);
  let cube_bounds = cube_1.getBounds();
  cube_1 = cube_1.translate([bounds[0].x, bounds[0].y,0]);
  csgText = csgText.translate([(-2 * cube_bounds[1].x/2), (-2 * cube_bounds[1].y/2),0]);
  return [ csgText, cube_1.setColor(html2rgb('#3f51b5'))];
}
`;
  };
  //const throttle = (callback, limit) => {
  //
  //  var wait = false;
  //  return function () {
  //    if (!wait) {
  //
  //      callback.apply(null, arguments);
  //      wait = true;
  //      setTimeout(function () {
  //        wait = false;
  //      }, limit);
  //    }
  //  }
  //
  //};

  useEffect(() => {
    let processor = document.init_openjscad('openjscad_designer_viewer', getCode(), getParams());

    processor.onchange = () => {
      console.log('Processor state');
      console.log(processor.getState());
      setState({...state, processor_state: processor.getState()});
    };

    setProcessor(processor);

  },[]);

  const getStlAsString = (cb) => {

    processor.clearOutputFile();
    var blob = processor.currentObjectsToBlob();
    var extension = processor.selectedFormatInfo().extension;

    const reader = new FileReader();

    reader.addEventListener('loadend', (e) => {
      const data = e.srcElement.result;

      cb(data);
    });

    reader.readAsText(blob);
  };

  const downloadStl = (cb, name) => {

    processor.clearOutputFile();
    var blob = processor.currentObjectsToBlob();
    var extension = processor.selectedFormatInfo().extension;

    function onDone(data, downloadAttribute, blobMode, noData, blob) {

      processor.hasOutputFile = true;
      processor.downloadOutputFileLink.href = data;
      if (blobMode) {
        processor.outputFileBlobUrl = data;
      } else {
        // FIXME: what to do with this one ?
        // that.outputFileDirEntry = dirEntry // save for later removal
      }
      processor.downloadOutputFileLink.innerHTML = processor.downloadLinkTextForCurrentObject();
      processor.downloadOutputFileLink.setAttribute('download', name + '.stl');
      if (noData) {
        processor.downloadOutputFileLink.setAttribute('target', '_blank');
      }
      processor.enableItems();

      document.getElementsByClassName('downloadOutputFileLink')[0].click()
      cb();
    }

    if (processor.viewedObject) {
      processor._generateOutputFile(extension, blob, onDone, processor);
      if (processor.ondownload) processor.ondownload(processor);
    }
  }


  const update = () => {
    //console.log(getCode());
    if(processor){
      processor.abort();
      processor.setJsCad(getCode(), 'nametag', getParams());
    };
  };


  const update_throttled = throttle(update,1000);

  const onChange = (ev, value) => {

    const data = { ...config };

    if(typeof ev.target !== 'undefined'){
      if(ev.target.type === 'checkbox'){
        console.log('checkbox');
        if(ev.target.checked){
          data[ev.target.name] = true;
        }else{
          data[ev.target.name] = false;
        }
      }else{
        console.log('NEXT ONCHANGE CALLED ' + ev.target.value);
        window['globalnt_' + ev.target.name] = ev.target.value;
        data[ev.target.name] = ev.target.value;
      }
    }
    setConfig(data);

  }

  useEffect(() => {
    update_throttled();
  },[config]);

  useEffect(() => {
    //console.log('NEXT NAMEPLATE ' + loading);

    if(nextNameplate && state.processor_state === 2){
      nextNameplate();
      nextNameplate = false;
    }
  },[state.processor_state]);

  const generateNametag = (names) => {
    console.log(rFontTrigger + ' next');
    rFontTrigger += 1;
    let old_font = config.font + '';
    let current_name = names.pop();
    console.log('RANDOM FONT TRIGGER NEXT ' + (randomFontTrigger+1));
    setRandomFontTrigger(rFontTrigger);
    setTimeout(() => {
      let new_font = config.font + '';
      console.log('NEXT ' + old_font + ' ' + new_font);
      setConfig({...config, text: current_name, font: window.globalnt_font});

      nextNameplate = () => {
        //console.log('NEXT NAMEPLATE');
        downloadStl(() => {
    setTimeout(() => {
          generateNametag(names);
    }, 1000);
        }, current_name + '');
      };
    }, 1000);
  }

  const generateNametags = () => {

    generateNametag(namelist);
  }

  return (
    <div className={classes.root}>
      { loading &&
        <LoadingIndicator />
      }
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={4}
          md={4}
          xl={4}
          xs={12}
        >
          <Grid
            container
            spacing={4}
            key={'nametag_config_form'}
          >
            <Grid
              item
              xs={9}
            >
              <FontPicker
                id="nametage_font"
                name="font"
                onChange={onChange}
                randomFontTrigger={randomFontTrigger}
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <Button onClick={() => {
                setRandomFontTrigger(randomFontTrigger+1);
              }}
              style={{width: '100%'}}
              variant='contained'
              disabled={(state.processor_state !== 2)}
            >
              Random font
            </Button>
          </Grid>
          { env.env === 'dev' &&
          <Grid
            item
            xs={6}
          >
            <Button onClick={() => {
              generateNametags();
            }}
            style={{width: '100%'}}
            variant='contained'
            disabled={(state.processor_state !== 2)}
          >
            Download Nametag
          </Button>
        </Grid>
        }
        <Grid
          item
          xs={12}
          style={{paddingTop:0,}}
        >
          <TextField
            id="nametag_text"
            label="Text"
            type="text"
            name="text"
            value={config.text}
            onChange={onChange}
            style={{
              width: '100%',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{paddingTop:0,}}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">Backplate</FormLabel>
            <ButtonGroup color="default" style={{marginTop:'6px',width:'100%',display:'flex',alignItems:'stretch',alignContent:'stretch'}} aria-label="outlined primary button group">
              <Button className={(config.backplateShape === 'rectangle'?classes.selectedButton:null)} disabled={(config.backplateShape === 'rectangle'?true:false)}>
                <CheckBoxOutlineBlankSharpIcon onClick={() => {setConfig({...config, backplateShape:'rectangle'});}}/>
              </Button>
              <Button className={(config.backplateShape === 'round_rectangle'?classes.selectedButton:null)} disabled={(config.backplateShape === 'round_rectangle'?true:false)}>
                <CropSquareRoundedIcon onClick={() => {setConfig({...config, backplateShape:'round_rectangle'});}}/>
              </Button>
              <Button className={(config.backplateShape === 'circle'?classes.selectedButton:null)} disabled={(config.backplateShape === 'circle'?true:false)}>
                <PanoramaFishEyeSharpIcon onClick={() => {setConfig({...config, backplateShape:'circle'});}}/>
              </Button>
              <Button className={(config.backplateShape === 'none'?classes.selectedButton:null)} disabled={(config.backplateShape === 'none'?true:false)}>
                <CloseSharpIcon onClick={() => {setConfig({...config, backplateShape:'none'});}}/>
              </Button>
            </ButtonGroup>
          </FormControl>

        </Grid>
        <Grid
          item
          xs={3}
          style={{paddingTop:0,}}
        >
          <TextField
            id="nametag_textThickness"
            label="Text thickness"
            type="number"
            name="textThickness"
            value={config.textThickness}
            onChange={onChange}
            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment>, }}
          />
        </Grid>
        <Grid
          item
          xs={3}
          style={{paddingTop:0,}}
        >
          <TextField
            id="nametag_backplateThickness"
            label="Backplate thickness"
            type="number"
            name="backplateThickness"
            value={config.backplateThickness}
            onChange={onChange}
            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment>, }}
          />
        </Grid>
        <Grid
          item
          xs={3}
          style={{paddingTop:0,}}
        >
          <TextField
            id="nametag_paddingTop"
            label="Padding top"
            type="number"
            name="paddingTop"
            value={config.paddingTop}
            onChange={onChange}
            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment>, }}
          />
        </Grid>
        <Grid
          item
          xs={3}
          style={{paddingTop:0,}}
        >
          <TextField
            id="nametag_paddingRight"
            label="Padding right"
            type="number"
            name="paddingRight"
            value={config.paddingRight}
            onChange={onChange}
            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment>, }}
          />
        </Grid>
        <Grid
          item
          xs={3}
          style={{paddingTop:0,}}
        >
          <TextField
            id="nametag_paddingBottom"
            label="Padding bottom"
            type="number"
            name="paddingBottom"
            value={config.paddingBottom}
            onChange={onChange}
            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment>, }}
          />
        </Grid>
        <Grid
          item
          xs={3}
          style={{paddingTop:0,}}
        >
          <TextField
            id="nametag_paddingLeft"
            label="Padding left"
            type="number"
            name="paddingLeft"
            value={config.paddingLeft}
            onChange={onChange}
            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment>, }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        key={'nametag_checkout_info'}
      >
        <Grid
          item
          xs={12}

        >
          <Button onClick={() => {
            setOrderInfo({
              order_id:    0,
              checksum:    '',
              coupon_code: '',
              filename:    '',
            });
            setLoading(true);
            setTimeout(() => {
              console.log('loading: ' + loading);
              setState({...state, checkout_form_open: true});
            }, 100);
          }}
          style={{width: '100%'}}
          variant='contained'
          disabled={(state.processor_state !== 2)}
        >
          <Typography variant="h3" component="p" style={{marginRight: '5px', }}>
            Get STL file
          </Typography>
          <Typography variant="h3" component="p" style={{fontSize: '15px',marginRight: '5px', color: '#449721', }}>
            $2.50 
          </Typography>
        </Button>
      </Grid>
    </Grid>

  </Grid>
  <Grid
    item
    lg={8}
    md={8}
    xl={8}
    xs={12}
    style={{
      maxWidth: '100%',
      position: 'relative',
    }}
  >
    <div id="header" style={{display:'none'}}>
      <div id="errordiv"></div>
    </div>

    { (state.processor_state !== 2) &&
        <LoadingIndicator />
    }

    <div style={{width:'100%', height:'100%'}} onContextMenu={() => false} id="openjscad_designer_viewer"></div>


    <div id="tail" style={{display:'none'}}>
      <div id="statusdiv"></div>
    </div>

  </Grid>
</Grid>
{ state.checkout_form_open && 
    <CheckoutDialog
      model_config={config}
      orderInfo={orderInfo}
      setOrderInfo={setOrderInfo}
      getStlAsString={getStlAsString}
      closeDialog={() => {
        setState({...state, checkout_form_open: false});
      }}
      onUploadReady={() => {
        setLoading(false);
      }}
    />
}
    </div>
  );
};

export default DesignerNeu;
