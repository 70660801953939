import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={4}
          md={4}
          xl={4}
          xs={12}
        >
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          xl={8}
          xs={12}
          style={{
            maxWidth: '100%',
            position: 'relative',
          }}
        >
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={4}
              md={4}
              xl={4}
              xs={12}
            >
              <a href="/model_editor">
                Model editor
              </a>
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              xl={4}
              xs={12}
            >
              Regiment base editor
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              xl={4}
              xs={12}
            >
              Miniature nametag designer
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              xl={4}
              xs={12}
            >
              Base editor
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              xl={4}
              xs={12}
            >
              Evil dwarf hat editor
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
